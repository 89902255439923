import { genericService } from "./genericService";

const section = "Transport";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(
    section,
    "transports",
    name,
    from,
    limit,
    active
  );
}
function getAll() {
  return genericService.get("Transport/ListAllTransport");
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    NameTransport: item.nameTransport,
    CUIT: item.cuit,
    TransportAddress: item.transporttAddress,
    TransportTelephone: item.transportTelephone,
    TransportEmail: item.transportEmail,
    Contact: item.contact,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    NameTransport: item.nameTransport,
    CUIT: item.cuit,
    TransportAddress: item.transporttAddress,
    TransportTelephone: item.transportTelephone,
    TransportEmail: item.transportEmail,
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const transportService = {
  getAll,
  filter,
  remove,
  create,
  update,
};
