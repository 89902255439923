<template>
  <div>
    <Title :pages="[{ icon: 'clipboard-list', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service, name_filter, (current_page - 1) * $store.state.config.items_per_page)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <!--Filter-->
      <div class="row mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <v-text-field data-vv-as="nombre" :label="$t('Nombre') + '*'" name="zone" v-model="name_filter"></v-text-field>
              </div>
              <div class="col-md-4">
                <v-checkbox :label="$t('Activo')" v-model="active_filter"></v-checkbox>
              </div>

              <div class="col-md-4 d-flex align-items-center justify-content-end pr-10">
                <v-btn color="primary" dark @click="loadList(service)">{{ $t("Buscar") }}</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter -->

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" :label="$t('Buscar')" single-line hide-details></v-text-field>
        </v-card-title>

        <v-data-table :headers="headers" :items="list" :single-expand="false" :loading="loading" :expanded.sync="expanded" show-expand class="elevation-1" hide-default-footer disable-pagination>
          <template v-slot:item="{ item, index, expand, isExpanded }">
            <tr>
              <td>
                <font-awesome-icon v-if="!isExpanded" icon="chevron-down" @click="expand(!isExpanded)" class="cursor" />
                <font-awesome-icon v-else icon="chevron-up" @click="expand(!isExpanded)" class="cursor" />
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ (pagination.page - 1) * pagination.itemsPerPage + index + 1 }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.name }}
              </td>

              <td class="text-center  px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }" v-if="create_access">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="addRemitoItem(item)">
                      add
                    </v-icon>
                  </template>
                  <span>Agregar item</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>{{ $t("Ver") }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="update_access">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(item)">
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="delete_access">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" class="mr-2" @click="deleteItem(item)">
                      delete
                    </v-icon>
                  </template>
                  <span>{{ $t("Eliminar") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="headersRemitosItems"
                :items="item.items"
                :search="search"
                :loading-text="$t('cargando_espere')"
                hide-default-footer
                disable-pagination
                class="background-color: grey  lighten-3"
              >
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.item.desde }}</td>
                    <td>{{ props.item.hasta }}</td>
                    <td>{{ props.item.measurementUnitType.name }}</td>
                    <td>{{ props.item.porecentaje }}</td>
                    <td>{{ props.item.price }}</td>
                    <td class="text-center  px-0">
                      <v-tooltip bottom v-if="update_access">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="blue" class="mr-2" @click="editTarifaItem(item, props.item)">
                            edit
                          </v-icon>
                        </template>
                        <span>{{ $t("Editar") }}</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="delete_access">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="red" class="mr-2" @click="deleteTarifaItem(item, props.item)">
                            delete
                          </v-icon>
                        </template>
                        <span>{{ $t("Eliminar") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                    {{ $t("Sin_resultados") + search }}
                  </v-alert>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <ModalTarifaItem
        v-if="create_access"
        :button_name="$t('Crear') + ' tarifa item'"
        modal_title="Tarifa item"
        :formBtn="formBtnItem"
        :dialog="dialog_tarifa_item"
        :parent="parent"
        :service="service"
        @updateDialog="
          (value) => {
            dialog_tarifa_item = value;
            closeTarifaItem();
          }
        "
        @loadList="loadList(service, name_filter, (current_page - 1) * $store.state.config.items_per_page)"
        :item="mainTarifaItem"
        @updateItem="
          (value) => {
            mainTarifaItem = value;
          }
        "
      />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteConfirm" />
      <DialogDelete
        :dialog_delete="dialog_delete_item"
        @updateDialogDelete="
          (value) => {
            dialog_delete_item = value;
          }
        "
        @deleteItemConfirm="deleteTarifaItemConfirm"
      />
    </v-container>
  </div>
</template>

<script>
import { rateSaleService } from "@/libs/ws/rateSaleService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import ModalTarifaItem from "./ModalTarifaItem.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  components: { Title, Modal, DialogDelete, ModalTarifaItem },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      parent: this,
      service: rateSaleService,
      page_title: this.$t("Tarifas_de_venta"),
      modal_title: this.$t("Tarifas_de_venta"),
      mainItem: {
        id: "",
        zone: "",
        isActive: true,
      },
      defaultItem: null,
      mainTarifaItem: { idSaleRate: null, measurementUnitType: null },
      defaultTarifaItem: null,
      headers: [
        { text: this.$t("Numero"), value: "number" },
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        { text: this.$t("Accion"), value: "enable", align: "center" },
      ],
      headersRemitosItems: [
        { text: "Desde", value: "desde" },
        { text: "Hasta", value: "hasta" },
        { text: "Unidad de medida", value: "measurementUnitType.name" },
        { text: "Porcentaje", value: "porecentaje" },
        { text: "Precio", value: "price" },
        { text: this.$t("Accion"), value: "enable", align: "center" },
      ],
      list: [],
      selected: [],
      dialog_increment: false,
      porcentage: 0,
      expanded: [],
      dialog_tarifa_item: false,
      dialog_delete_item: false,
      editedTarifaIndex: -1,
      deleteTarifaIndex: -1,
      viewdTarifaIndex: -1,
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.defaultTarifaItem = Object.assign({}, this.mainTarifaItem);
    this.loadList(this.service);
  },
  created: function() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.closeTarifaItem();
      }
    });
  },
  computed: {
    formBtnItem() {
      if (this.editedTarifaIndex === -1 && this.viewdTarifaIndex === -1) {
        return this.$t("Crear");
      } else if (this.viewdTarifaIndex === -1) {
        return "Editar";
      }
      return "";
    },
  },
  methods: {
    deleteConfirm() {
      this.delete(this.service);
    },
    addRemitoItem(tarifa) {
      this.mainTarifaItem.idSaleRate = tarifa.id;
      this.dialog_tarifa_item = true;
    },
    deleteTarifaItemConfirm() {
      this.service
        .removeItem(this.deleteId)
        .then((response) => {
          if (response.id > 0) {
            this.$toast.success("Se borro correctamente la tarifa", "OK");
            this.loadList(this.service, (this.current_page - 1) * this.$store.state.config.items_per_page);
          } else {
            this.$toast.error("Ocurrio un error al borrar la tarifa", "Error");
          }
          this.dialog_delete_item = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        });
    },
    delete(service) {
      service
        .remove(this.deleteId)
        .then((response) => {
          if (response.id > 0) {
            this.$toast.success("Se borro correctamente la tarifa", "OK");
            this.loadList(this.service, (this.current_page - 1) * this.$store.state.config.items_per_page);
          } else {
            this.$toast.error("Ocurrio un error al borrar la tarifa", "Error");
          }
          this.dialog_delete = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        });
    },
    editTarifaItem(tarifa, item) {
      this.errors.clear();
      this.editedTarifaIndex = tarifa.items.indexOf(item);
      this.mainTarifaItem = Object.assign({}, item);
      this.dialog_tarifa_item = true;
    },
    deleteTarifaItem(tarifa, item) {
      this.deleteTarifaIndex = tarifa.items.indexOf(item);
      this.deleteId = item.id;
      this.dialog_delete_item = true;
    },
    closeTarifaItem() {
      this.mainTarifaItem = Object.assign({}, this.defaultTarifaItem);
      this.dialog_tarifa_item = false;
      this.editedTarifaIndex = -1;
      this.viewdTarifaIndex = -1;
    },
    loadList(service) {
      this.loading = true;

      service
        .getAll()
        .then((response) => {
          console.log(response);
          this.list = response;
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
