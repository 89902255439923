import { genericService } from "./genericService";

const section = "TypeVehicle";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "types", name, from, limit, active);
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    Type: item.type,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    Type: item.type,
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const typeVehicleService = {
  filter,
  remove,
  create,
  update,
};
