<template>
  <div>
    <Title
      :pages="[{ icon: 'map-marker', page: page_title.toUpperCase() }]"
    ></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="
          loadList(
            service,
            name_filter,
            (current_page - 1) * $store.state.config.items_per_page
          )
        "
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <!--Filter-->
      <div class="row mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtro") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <v-text-field
                  data-vv-as="nombre"
                  :label="$t('Nombre')"
                  name="zone"
                  v-model="name_filter"
                ></v-text-field>
              </div>
              <div class="col-md-4">
                <v-checkbox
                  :label="$t('Activo')"
                  v-model="active_filter"
                ></v-checkbox>
              </div>

              <div
                class="col-md-4 d-flex align-items-center justify-content-end pr-10"
              >
                <v-btn
                  color="primary"
                  dark
                  v-on="on"
                  @click="loadList(service)"
                  >{{ $t("Buscar") }}</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter -->

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ $t("Zonas") }}</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('Buscar')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          :loading-text="$t('cargando_espere')"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item="props">
            <tr>
              <td>
                {{
                  (pagination.page - 1) * pagination.itemsPerPage +
                    props.index +
                    1
                }}
              </td>
              <td>{{ props.item.zone }}</td>
              <td class="justify-content-center layout px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="update_access"
                      v-on="on"
                      small
                      color="blue"
                      class="mr-2"
                      @click="editItem(props.item)"
                    >
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              No se encontraron resultados para "{{ search }}".
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <div v-if="cant_pages > 1" class="text-center">
        <v-pagination
          v-if="list_access"
          class="mt-5 mb-5"
          v-model="current_page"
          :length="cant_pages"
          total-visible="10"
          circle
          @next="loadPage(service)"
          @previous="loadPage(service)"
          @input="loadPage(service)"
        ></v-pagination>
      </div>

      <DialogDelete
        :dialog_delete="dialog_delete"
        @updateDialogDelete="updateDialogDelete"
        @deleteItemConfirm="deleteItemConfirm"
      />
    </v-container>
  </div>
</template>

<script>
import { zoneService } from "@/libs/ws/zoneService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title, Modal, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      parent: this,
      service: zoneService,
      page_title: "Zonas",
      modal_title: "Zona",
      mainItem: {
        id: "",
        zone: "",
        isActive: true,
      },
      defaultItem: null,
      headers: [
        { text: this.$t("Numero"), value: "number" },
        {
          text: this.$t("Nombre"),
          align: "left",
          sortable: true,
          value: "zone",
        },
        { text: this.$t("Accion"), value: "enable", align: "center" },
      ],
      list: [],
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.loadList(this.service);
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
    },
  },
};
</script>
