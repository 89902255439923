var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"pages":[{ icon: 'user-cog', page: _vm.page_title.toUpperCase() }]}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.create_access)?_c('Modal',{attrs:{"button_name":_vm.$t('Crear') + ' ' + _vm.modal_title,"modal_title":_vm.modal_title,"formBtn":_vm.formBtn,"dialog":_vm.dialog,"parent":_vm.parent,"service":_vm.service,"item":_vm.mainItem},on:{"updateDialog":_vm.updateDialog,"loadList":function($event){return _vm.loadRoles()},"updateItem":function (newItem) {
          _vm.mainItem = newItem;
        }}}):_vm._e(),(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.page_title))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('Buscar'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('cargando_espere'),"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(props.item.id)+" ")]),_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',{staticClass:"justify-content-center layout px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.update_access)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(props.item)}}},on),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Editar")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewItem(props.item)}}},on),[_vm._v(" remove_red_eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Ver")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(props.item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Eliminar")))])])],1)])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("sin_resultados") + _vm.search)+" ")])]},proxy:true}],null,false,3810952353)})],1):_vm._e(),_c('DialogDelete',{attrs:{"dialog_delete":_vm.dialog_delete},on:{"updateDialogDelete":_vm.updateDialogDelete,"deleteItemConfirm":_vm.deleteItemConfirm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }