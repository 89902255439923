import { genericService } from "./genericService";

const section = "Driver";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "drivers", name, from, limit, active);
}

function getAll() {
  return genericService.get("Driver/ListAllDriver");
}

function remove(id) {
  return genericService.remove(section, id);
}

function getDriverLat(driverId) {
  return genericService.get("Driver/GetPositionDriver/" + driverId);
}

function create(item) {
  const param = {
    NameDriver: item.nameDriver,
    IDVehicle: item.vehicle.id,
    CUIT: item.cuit,
    DriverAddress: item.driverAddress,
    DriverEmail: item.driverEmail,
    EnabledAPP: item.enabledAPP,
    UserAPP: item.userAPP,
    PassAPP: item.passAPP,
    driverTelephone: item.driverTelephone,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    NameDriver: item.nameDriver,
    IDVehicle: item.vehicle.id,
    CUIT: item.cuit,
    DriverAddress: item.driverAddress,
    DriverEmail: item.driverEmail,
    EnabledAPP: item.enabledAPP,
    UserAPP: item.userAPP,
    PassAPP: item.passAPP,
    driverTelephone: item.driverTelephone,
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const choferesService = {
  getDriverLat,
  getAll,
  filter,
  remove,
  create,
  update,
};
