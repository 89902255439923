<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="750px">
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col-md-4" v-if="!sinRuta">
                <v-text-field v-model="item.Orden" label="Orden" outlined disabled></v-text-field>
              </div>
              <div :class="sinRuta ? 'col-md-6' : 'col-md-4'">
                <v-text-field v-model="item.remito" :label="'N° de ' + $t('Remito')" name="nroRemito" data-vv-as="Numero de Remito" autocomplete="off" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("nroRemito") }}</span>
              </div>
              <div :class="sinRuta ? 'col-md-6' : 'col-md-4'">
                <v-combobox
                  v-model="item.estate"
                  data-vv-as="Estado"
                  name="estado"
                  item-text="name"
                  item-value="id"
                  :items="states_create"
                  label="Estado"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("estado") }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <v-combobox
                  v-model="item.client"
                  data-vv-as="Cliente"
                  name="cliente"
                  item-text="nameClient"
                  item-value="id"
                  :items="clients"
                  :label="$t('Clientes')"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("cliente") }}</span>
              </div>
              <div class="col-md-6">
                <v-combobox
                  v-model="item.subClient"
                  data-vv-as="Sub cliente"
                  name="subcliente"
                  :item-text="subcli"
                  item-value="id"
                  :items="subclients"
                  :label="'Sub ' + $t('Clientes')"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                  :hide-no-data="!search"
                  :search-input.sync="search"
                  @keyup="loadSubclients()"
                  :loading="loading_subclientes"
                >
                </v-combobox
                ><span class="red--text">{{ errors.first("subcliente") }}</span>
              </div>
            </div>

            <div class="row justify-content-around">
              <div class="col-md-3">
                <v-text-field v-model="item.pallets" type="number" min="0" :label="$t('Pallets')" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.packages" type="number" min="0" :label="$t('Bultos')" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.kg" type="number" min="0" label="KG" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.cubicMeters" type="number" min="0" label="M3" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.declaredValue" label="Valor declarado" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.rack" type="number" min="0" label="Rack" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.bobina" type="number" min="0" label="Bobina" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.tambores" type="number" min="0" label="Tambores" :disabled="isViewModal()"></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <v-text-field v-model="item.observation" :label="$t('Observaciones')" :disabled="isViewModal()"></v-text-field>
              </div>
            </div>
            <div class="row" v-if="!sinRuta">
              <div class="col-md-12 ">
                <h6 class="centerText">{{ $t("Adicionales") }}</h6>
              </div>
            </div>
            <div class="row" v-if="!sinRuta">
              <div class="col-md-3 ">
                <v-checkbox v-model="item.assistant" :label="$t('Peon')" :disabled="isViewModal() || itemRuta.quantityAssistant == 0"></v-checkbox>
              </div>
              <div class="col-md-3 ">
                <v-checkbox v-model="item.delays" :label="$t('Demoras')" :disabled="isViewModal() || itemRuta.quantityDelay == 0"></v-checkbox>
              </div>
              <div class="col-md-3 ">
                <v-checkbox v-model="item.stays" :label="$t('Estadias')" :disabled="isViewModal() || itemRuta.quantityStays == 0"></v-checkbox>
              </div>
              <div class="col-md-3 ">
                <v-checkbox v-model="item.turns" :label="$t('Vueltas')" :disabled="isViewModal() || itemRuta.quantityTurns == 0"></v-checkbox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text v-if="formBtn != '' && formBtn != 'Crear'" type="submit">{{ formBtn }}</v-btn>
            <v-menu v-if="formBtn != 'Editar' && formBtn != ''" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-1" color="success" dark v-bind="attrs" v-on="on" @click="createItem(0)">
                  {{ $t("Guardar") }}
                </v-btn>

                <v-btn class="ml-1" color="success" dark v-bind="attrs" v-on="on" @click="createItem(2)">
                  {{ $t("siguiente_remito_datos") }}
                </v-btn>
                <v-btn class="ml-1" color="success" dark v-bind="attrs" v-on="on" @click="createItem(1)">
                  {{ $t("siguiente_remito") }}
                </v-btn>
              </template>
              <!--
              <v-list>
                <v-list-item>
                  <v-list-item-title  class="cursor">
                    Crear
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="createItem(1)" class="cursor">
                    
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="createItem(2)" class="cursor">
                    </v-list-item-title
                  >
                </v-list-item> 
              </v-list>-->
            </v-menu>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { clientService } from "@/libs/ws/clientService";
import { subClientService } from "@/libs/ws/subClientService";
import { remitosService } from "@/libs/ws/remitosService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    itemRuta: Object,
    sinRuta: Boolean,
    states_create: Array,
  },
  data() {
    return {
      show: false,
      clients: [],
      subclients: [],
      loading_subclientes: false,
      search: " ",
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
      /* if (this.dialog) {
        this.getOrdenNumber();
      } */
    },
  },
  mounted() {
    this.loadClients();
    this.loadSubclients();
  },

  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadSubclients() {
      this.loading_subclientes = true;
      subClientService
        .filter(this.search == "" ? " " : this.search, 0, 100, 1)
        .then((response) => {
          this.subclients = response.list;
          this.loading_subclientes = false;
        })
        .catch((e) => {
          console.log("service.load error");
          this.loading_subclientes = false;
          console.log(e);
        });
    },
    subcli: (item) => (item.id == 0 ? "" : item.nameSubClient + " (" + item.subClientAddress + ")"),

    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem(tipo = 0) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          //e.preventDefault();
          if (this.item.id > 0) {
            this.item.IdTrip = 0;
            remitosService
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            remitosService
              .createInBag(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false, tipo);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
