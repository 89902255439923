<template>
  <div>
    <v-container fluid style="margin-top:0px;margin-bottom:0px">
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + $t('Vehiculo')"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service, name_filter, (current_page - 1) * $store.state.config.items_per_page)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <!--Filter-->
      <div class="row mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtro") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <v-text-field data-vv-as="nombre" label="Patente" name="zone" v-model="name_filter"></v-text-field>
              </div>
              <div class="col-md-4">
                <v-checkbox :label="$t('Activo')" v-model="active_filter"></v-checkbox>
              </div>

              <div class="col-md-4 d-flex align-items-center justify-content-end pr-10">
                <v-btn color="primary" dark @click="loadList(service)">{{ $t("Buscar") }}</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter -->

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ $t("Vehiculos") }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" :label="$t('Buscar')" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" :loading-text="$t('cargando_espere')" hide-default-footer disable-pagination>
          <template v-slot:item="props">
            <tr>
              <td>
                {{ (pagination.page - 1) * pagination.itemsPerPage + props.index + 1 }}
              </td>
              <td>{{ props.item.patent }}</td>
              <td>{{ props.item.type.type }}</td>
              <td>{{ props.item.maxCharge }}</td>
              <td>{{ props.item.capacity }}</td>
              <td>{{ props.item.companyTransport.nameTransport }}</td>
              <td>{{ props.item.urlSatelital }}</td>
              <td>{{ props.item.userSatelital }}</td>
              <td>{{ props.item.passSatelital }}</td>

              <td class="justify-content-center layout px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)">
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>{{ $t("Ver") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning"> No se encontraron resultados para "{{ search }}". </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <div v-if="cant_pages > 1" class="text-center">
        <v-pagination
          class="mt-5 mb-5"
          v-model="current_page"
          :length="cant_pages"
          total-visible="10"
          circle
          @next="loadPage(service)"
          @previous="loadPage(service)"
          @input="loadPage(service)"
        ></v-pagination>
      </div>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import { vehiculoService } from "@/libs/ws/vehiculoService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./ModalVehiculo.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  components: { Modal, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      parent: this,
      service: vehiculoService,
      page_title: "Vehiculos",
      modal_title: "Vehiculo",
      mainItem: {
        id: "",
        capacity: "",
        companyTransport: "",
        idOrganizacion: "",
        maxCharge: "",
        observation: "",
        patent: "",
        satelital: false,
        type: "",
        isActive: true,
        urlSatelital: "",
        userSatelital: "",
        passSatelital: "",
      },
      defaultItem: null,
      headers: [
        { text: this.$t("Numero"), value: "number" },
        {
          text: "Patente",
          align: "left",
          sortable: true,
          value: "patent",
        },
        {
          text: "Tipo",
          align: "left",
          sortable: true,
          value: "type.type",
        },
        {
          text: "Carga maxima",
          align: "left",
          sortable: true,
          value: "maxCharge",
        },
        {
          text: this.$t("Capacidad"),
          align: "left",
          sortable: true,
          value: "capacity",
        },
        {
          text: "Transporte",
          align: "left",
          sortable: true,
          value: "companyTransport.nameTransport",
        },
        {
          text: "Url satelital",
          align: "left",
          sortable: true,
          value: "urlSatelital",
        },
        {
          text: "Usuario satelital",
          align: "left",
          sortable: true,
          value: "userSatelital",
        },
        {
          text: "Contraseña satelital",
          align: "left",
          sortable: true,
          value: "passSatelital",
        },
        { text: this.$t("Accion"), value: "enable", align: "center" },
      ],
      list: [],
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.loadList(this.service);
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
    },
  },
};
</script>
