<template>
  <div>
    <div class="row shipping-monitoring ">
      <div class="col-md-8">
        <div>
          <div :class="getClass(stage.delivery)">
            {{ stage.order }}
          </div>
        </div>
        <p class="mb-0">{{ stage.description }}</p>
        <span>{{ toDateString(stage.delivery) }}</span>
      </div>

      <div class="col-md-4">
        <div class="float-right" style="padding-top:12px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <font-awesome-icon v-on="on" v-if="stage.image.length" color="#999" icon="image" @click="dialog_images = !dialog_images" class="cursor" />
            </template>
            <span>Imagenes</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <ModalImages :dialog_images="dialog_images" :stage="stage" @close="dialog_images = false" />
  </div>
</template>

<script>
import ModalImages from "../Rutas/ModalImages";
export default {
  name: "Stage",
  components: { ModalImages },
  props: {
    stage: Object,
  },
  data() {
    return {
      dialog_images: false,
    };
  },
  mounted() {},
  updated() {},
  methods: {
    toDateString(dateIsoString) {
      if (dateIsoString == null) {
        return dateIsoString;
      }
      //"2022-04-18T16:19:40-03:00"
      var date = dateIsoString.split("T");
      var hora = date[1].split("-");
      date = date[0].split("-");
      date = date[2] + "-" + date[1] + "-" + date[0] + " " + hora[0];
      return date;
    },
    getClass(delivery) {
      if (delivery != null) {
        return "circle-monitoring bg-entregado";
      } else {
        return "circle-monitoring bg-retenido";
      }

      /*
      switch (estado) {
        case 1:
          return "circle-monitoring bg-autorizado";
        case 2:
          return "circle-monitoring bg-retenido";
        case 3:
          return "circle-monitoring bg-no-entregado";
        case 4:
          return "circle-monitoring bg-entregado";
        case 5:
          return "circle-monitoring bg-despachado";
        case 6:
          return "circle-monitoring bg-en-camino";
        default:
          return "circle-monitoring bg-en-camino";
      }
      */
    },
  },
};
</script>
