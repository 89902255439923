import { genericService } from "./genericService";

const section = "Warehouse";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "warehouses", name, from, limit, active);
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    contact: item.contact,
    IdLocality: item.locality.id,
    nameWarehouse: item.nameWarehouse,
    WarehouseAddress: item.warehouseAddress,
    WarehouseEmail: item.warehouseEmail,
    WarehouseTelephone: item.warehouseTelephone,
    IdClient: item.client.id,
    isActive: item.isActive,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    contact: item.contact,
    IdLocality: item.locality.id,
    nameWarehouse: item.nameWarehouse,
    WarehouseAddress: item.warehouseAddress,
    WarehouseEmail: item.warehouseEmail,
    WarehouseTelephone: item.warehouseTelephone,
    IdClient: item.client.id,
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const warehouseService = {
  filter,
  remove,
  create,
  update,
};
