<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->

            <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="item.fecha" :label="$t('Fecha_inicio')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :disabled="isViewModal()"></v-text-field>
              </template>
              <v-date-picker v-model="item.fecha" min="1950-01-01"></v-date-picker>
            </v-menu>

            <v-menu
              ref="menu"
              v-model="menu_time"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="item.time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.time"
                  label="Hora de inicio"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-validate="'required'"
                  v-bind="attrs"
                  name="time"
                  v-on="on"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("time") }}</span>
              </template>
              <v-time-picker v-if="menu_time" v-model="item.time" full-width @click:minute="$refs.menu.save(item.time)"></v-time-picker>
            </v-menu>

            <v-combobox
              v-model="item.cliente"
              data-vv-as="cliente"
              name="cliente"
              item-text="nameClient"
              item-value="id"
              :items="clients"
              label="Cliente*"
              autocomplete="off"
              v-validate="'required'"
              :disabled="isViewModal()"
            ></v-combobox>
            <span class="red--text">{{ errors.first("cliente") }}</span>

            <v-combobox
              v-model="item.usersComunication"
              data-vv-as="UsersComunication"
              name="UsersComunication"
              item-text="name"
              item-value="id"
              :items="users"
              label="Usuarios*"
              autocomplete="off"
              :disabled="isViewModal()"
              multiple
            ></v-combobox>
            <!-- <span class="red--text">{{ errors.first("UsersComunication") }}</span> -->

            <p v-if="!item.id > 0" class="mb-0 fs-16" :disabled="item.id > 0">Seleccione las imagenes que desea agregar al viaje</p>
            <v-file-input v-if="!item.id > 0" :disabled="item.id > 0" v-model="imagesUpload" counter multiple truncate-length="15" @change="handleUploadFile"></v-file-input>

            <v-textarea outlined name="Observaciones" label="Observaciones" v-model="item.observaciones" :disabled="isViewModal()"></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { clientService } from "@/libs/ws/clientService";
import { imageUtils } from "@/libs/utils/imageUtils";
import { usersService } from "@/libs/ws/usersService";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    eventUpdated: Function,
  },
  data() {
    return {
      clients: [],
      menu_time: false,
      imagesUpload: [],
      users: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
      this.setDateTime(this.item.fecha);
    },
  },
  mounted() {
    this.loadClients();
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      usersService
        .getAll()
        .then((response) => {
          this.users = response.users;
        })
        .catch((e) => {
          console.log("error service user load");
          console.log(e);
        });
    },

    setDateTime(fechaString) {
      // Crear un objeto Date a partir del string
      var fecha = new Date(fechaString);

      // Obtener los componentes de la fecha
      var dia = fecha.getDate();
      var mes = fecha.getMonth() + 1; // Los meses son indexados desde 0
      var anio = fecha.getFullYear();

      // Formatear la fecha
      var fechaFormateada = `${anio}-${mes < 10 ? "0" + mes : mes}-${dia < 10 ? "0" + dia : dia}`;

      // Obtener los componentes de la hora
      var horas = fecha.getHours();
      var minutos = fecha.getMinutes();

      // Formatear la hora
      var horaFormateada = `${horas < 10 ? "0" + horas : horas}:${minutos < 10 ? "0" + minutos : minutos}`;

      this.item.fecha = fechaFormateada;
      this.item.time = horaFormateada;
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    async handleUploadFile() {
      if (this.imagesUpload.length > 0) {
        for (const element of this.imagesUpload) {
          var obj = { image: null };

          obj.image = await imageUtils.getBase64(element);

          this.item.images.push(obj);
        }
      }
    },
    createItem() {
      this.$validator.validate().then(async (valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                  this.$emit("eventUpdated", response); // Emite el evento con el evento actualizado
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
