<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="1000px">
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <ModalAddImages :item="itemAddImages" :dialog="dialog_add_image" @close="dialog_add_image = false" @uploadImages="addImages" :loading="loadingAddImage" />

          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div
                class="col-md-6"
                style="text-align: center;
                      align-items: center;
                      justify-content: center;
                      display: flex;
                      min-height:350px;
                      "
              >
                <v-carousel v-if="item.images != '' && item.images != null" hide-delimiters :show-arrows="item.images.length > 1">
                  <v-carousel-item v-for="(item, image) in item.images" :key="image" :src="item.image">
                    <div style="width: 100%; height: 100%; cursor: pointer;" @click="openImageURL(item.image)"></div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-if="!isExternal" v-on="on" color="red" class="" style="position:absolute; top: 10px;right: 10px;" @click="deleteImage(item)">
                          delete
                        </v-icon>
                      </template>
                      <span>{{ $t("Eliminar") }}</span>
                    </v-tooltip>
                  </v-carousel-item>
                </v-carousel>

                <p v-else style="font-size: 22px ; text-align:center ">
                  {{ $t("Sin_imagenes") }}
                </p>
              </div>
              <div class="col-md-6">
                <h2>Informacion entrega</h2>
                <hr />
                <p v-if="item != null && item.estate != null" style="font-size: 22px; max-width:200px" v-html="getState(item.estate.id)"></p>

                <hr />
                <p style="font-size: 22px">{{ $t("Observaciones") }}: {{ item.observation }}</p>
                <hr />

                <a class="text-white" style="font-size: 22px " v-if="pdfRemitoURL != ''" :href="pdfRemitoURL" target="_blank">
                  <v-btn v-if="item.images != ''" small color="blue-grey" class="ma-2 white--text">
                    Descargar PDF

                    <v-icon right dark>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </a>

                <v-btn v-if="remitosService.ENTREGADO || remitosService.NO_ENTREGADO" small color="blue-grey" class="ma-2 white--text" @click="openAddImages(item)">
                  Subir imagen

                  <v-icon right dark>
                    mdi-upload
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { remitosService } from "@/libs/ws/remitosService";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
import { imageUtils } from "@/libs/utils/imageUtils";
import Constant from "@/constants/";
import ModalAddImages from "./ModalAddImages.vue";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    itemRemito: Object,
    reload: Function,
  },
  components: {
    ModalAddImages,
  },
  data() {
    return {
      remitosService: remitosService,
      show: false,
      pdfRemitoURL: "",
      isExternal: false,
      loadingAddImage: false,
      dialog_add_image: false,
      itemAddImages: {
        ruta: "",
        imagesUpload: [],
        images: [],
      },
      itemAddImagesDefault: null,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
      if (this.dialog) {
        this.getPDF(this.item.id);
        this.getImages(this.item.id);
      }
    },
  },
  mounted() {
    this.reportGenerateRemitos = false;

    const externalType = cryptoUtils.decrypt(localStorage.getItem("external"), Constant.SECRET_PHRASE);
    if (externalType != Constant.USER_TYPE_INTERNAL) {
      this.isExternal = true;
    }
  },

  methods: {
    getImages(id) {
      remitosService
        .getImages(id)
        .then((response) => {
          this.item.images = response;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openImageURL(image) {
      window.open(image);
    },
    deleteImage(image) {
      var param = {
        remito_id: this.item.id,
        image_id: image.idImage,
      };

      remitosService
        .removeImage(param)
        .then((response) => {
          const index = this.item.images.indexOf(image);
          this.item.images.splice(index, 1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async addImages(param) {
      console.log("param", param);
      this.loadingAddImage = true;

      param.ruta = this.itemAddImages.ruta;

      var images = [];
      if (param.imagesUpload.length > 0) {
        for (const element of param.imagesUpload) {
          var obj = { image: null };
          obj.image = await imageUtils.getBase64(element);
          images.push(obj);
        }
      }

      param.images = images;

      remitosService
        .addImages(param)
        .then((response) => {
          this.$toast.success("Las imagenes del viaje se guardaron correctamente.", "OK");
          this.dialog_add_image = false;
          this.itemAddImages = Object.assign({}, this.itemAddImagesDefault);
          this.loadingAddImage = false;
          this.$emit("updateDialog", false);
          this.$emit("reload", this.service);
        })
        .catch((e) => {
          this.loadingAddImage = false;
        });
    },

    openAddImages(item) {
      this.itemAddImages.ruta = item.id;
      this.itemAddImages.images = item.images;
      this.dialog_add_image = true;
    },
    getPDF(id) {
      remitosService
        .getPDF(id)
        .then((response) => {
          if (response.urlPDFRemito != undefined) {
            this.pdfRemitoURL = response.urlPDFRemito;
          } else {
            this.pdfRemitoURL = "";
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });

      return this.pdfRemitoURL;
    },
    getState(estado) {
      return remitosService.getStatesHTML(estado);
    },
  },
};
</script>
