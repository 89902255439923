var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"pages":[{ icon: 'map-marker', page: _vm.page_title.toUpperCase() }]}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.create_access)?_c('Modal',{attrs:{"button_name":_vm.$t('Crear') + ' ' + _vm.modal_title,"modal_title":_vm.modal_title,"formBtn":_vm.formBtn,"dialog":_vm.dialog,"parent":_vm.parent,"service":_vm.service,"item":_vm.mainItem},on:{"updateDialog":_vm.updateDialog,"loadList":function($event){_vm.loadList(
          _vm.service,
          _vm.name_filter,
          (_vm.current_page - 1) * _vm.$store.state.config.items_per_page
        )},"updateItem":function (newItem) {
          _vm.mainItem = newItem;
        }}}):_vm._e(),(_vm.list_access)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Filtro")))]),_c('font-awesome-icon',{attrs:{"icon":"filter"}})],1),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('v-text-field',{attrs:{"data-vv-as":"nombre","label":_vm.$t('Nombre'),"name":"zone"},model:{value:(_vm.name_filter),callback:function ($$v) {_vm.name_filter=$$v},expression:"name_filter"}})],1),_c('div',{staticClass:"col-md-4"},[_c('v-checkbox',{attrs:{"label":_vm.$t('Activo')},model:{value:(_vm.active_filter),callback:function ($$v) {_vm.active_filter=$$v},expression:"active_filter"}})],1),_c('div',{staticClass:"col-md-4 d-flex align-items-center justify-content-end pr-10"},[_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.loadList(_vm.service)}}},_vm.on),[_vm._v(_vm._s(_vm.$t("Buscar")))])],1)])])])]):_vm._e(),(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t("Zonas")))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('Buscar'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('cargando_espere'),"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s((_vm.pagination.page - 1) * _vm.pagination.itemsPerPage + props.index + 1)+" ")]),_c('td',[_vm._v(_vm._s(props.item.zone))]),_c('td',{staticClass:"justify-content-center layout px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(_vm.update_access)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(props.item)}}},on),[_vm._v(" edit ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Editar")))])])],1)])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" No se encontraron resultados para \""+_vm._s(_vm.search)+"\". ")])]},proxy:true}],null,false,277584020)})],1):_vm._e(),(_vm.cant_pages > 1)?_c('div',{staticClass:"text-center"},[(_vm.list_access)?_c('v-pagination',{staticClass:"mt-5 mb-5",attrs:{"length":_vm.cant_pages,"total-visible":"10","circle":""},on:{"next":function($event){return _vm.loadPage(_vm.service)},"previous":function($event){return _vm.loadPage(_vm.service)},"input":function($event){return _vm.loadPage(_vm.service)}},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}}):_vm._e()],1):_vm._e(),_c('DialogDelete',{attrs:{"dialog_delete":_vm.dialog_delete},on:{"updateDialogDelete":_vm.updateDialogDelete,"deleteItemConfirm":_vm.deleteItemConfirm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }