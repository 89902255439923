<template>
  <GmapMap
    :center="getCenter()"
    :zoom="11"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      disableDefaultUi: true,
    }"
    map-type-id="terrain"
    style="width: 100%; height: 500px"
  >
    <div v-for="remito of remitos" :key="remito.id">
      >
      <GmapMarker
        v-if="remito.subClient.latitudAddress != null && remito.subClient.longitudAddress != null"
        :position="getPosition(remito.subClient.latitudAddress, remito.subClient.longitudAddress)"
        :icon="getIcon(remito)"
        :label="{
          text: remito.orden.toString(),
          color: 'black',
          className: 'marker-position',
        }"
      >
      </GmapMarker>
      <!-- 
      <GmapInfoWindow>
        <div style="padding:5px">
          <span class="fs-15">{{ 12 }}</span>
        </div>
      </GmapInfoWindow> -->
    </div>
    <GmapMarker v-if="driver.latitud != null && driver.longitud != null" :position="getPosition(driver.latitud, driver.longitud)" :icon="require('@/assets/images/marker-truck.png')"> </GmapMarker>
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  name: "GeoMultipleMarkers",

  components: {},
  props: {
    remitos: Array,
    driver: Object,
    ruta: Object,
  },
  data() {
    return {
      lngMin: null,
      latMin: null,
      lngMax: null,
      latMax: null,
    };
  },
  computed: {
    google: gmapApi,
    remitosObject() {
      return this.remitos;
    },
  },
  watch: {
    remitosObject() {
      this.remitos.forEach((element) => {
        this.$set(element.shipping, "open", false);
      });
    },
  },
  mounted() {},
  methods: {
    getCenter() {
      var lat = (this.latMax + this.latMin) / 2;
      var lng = (this.lngMax + this.lngMin) / 2;
      return { lat: lat, lng: lng };
    },
    getIcon(assigned) {
      switch (assigned.estate.id) {
        case 1:
          return require("@/assets/images/geo/verde-oscuro.svg");
        case 2:
          return require("@/assets/images/geo/rojo.svg");
        case 3:
          return require("@/assets/images/geo/amarillo.svg");
        case 4:
          return require("@/assets/images/geo/verde.svg");
        case 5:
          return require("@/assets/images/geo/violeta.svg");
        case 6:
          return require("@/assets/images/geo/azul.svg");
      }
    },
    getPosition(lat, lng) {
      if (this.latMax == null || this.latMax < parseFloat(lat)) {
        this.latMax = parseFloat(lat);
      }

      if (this.latMin == null || this.latMin > parseFloat(lat)) {
        this.latMin = parseFloat(lat);
      }

      if (this.lngMax == null || this.lngMax < parseFloat(lng)) {
        this.lngMax = parseFloat(lng);
      }

      if (this.lngMin == null || this.lngMin > parseFloat(lng)) {
        this.lngMin = parseFloat(lng);
      }

      this.center = this.getCenter();

      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    },
  },
};
</script>
