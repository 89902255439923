<template>
  <div style="margin-bottom:50px;">
    <Title
      :pages="[{ icon: 'truck-moving', page: page_title.toUpperCase() }]"
    ></Title>

    <div
      style="padding:20px;margin-top:50px"
      v-if="list_access || create_access"
    >
      <v-tabs v-model="tab">
        <v-tab v-for="item in items" :key="item.key">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          @click="tab = 0"
          key="0"
          style="background:#f5f5f5"
          class="elevation-1"
        >
          <Clientes></Clientes>
        </v-tab-item>
        <v-tab-item
          @click="tab = 1"
          key="1"
          style="background:#f5f5f5"
          class="elevation-1"
        >
          <Subclientes></Subclientes>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Clientes from "./Clientes";
import Subclientes from "./Subclientes";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  components: {
    Title,
    Clientes,
    Subclientes,
  },
  mixins: [RolMixin],
  data() {
    return {
      page_title: "Clientes",
      tab: 0,
      list: [],
      items: [
        { tab: "Clientes", key: "0" },
        { tab: "Subclientes", key: "1" },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
