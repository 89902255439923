var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{staticStyle:{"width":"100%","height":"250px"},attrs:{"center":_vm.getCenter(),"zoom":8,"options":{
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
    disableDefaultUi: true,
  },"map-type-id":"terrain"}},_vm._l((_vm.remitos),function(remito){return _c('div',{key:remito.id},[_vm._v(" > "),(remito.subClient.latitudAddress != null && remito.subClient.longitudAddress != null)?_c('GmapMarker',{attrs:{"position":_vm.getPosition(remito.subClient.latitudAddress, remito.subClient.longitudAddress),"icon":_vm.getIcon(remito)}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }