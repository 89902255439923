import { genericService } from "./genericService";

const section = "SecurityCompany";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "companies", name, from, limit, active);
}

function remove(id) {
  return genericService.remove(section, id);
}
function getAll() {
  return genericService.get("SecurityCompany/ListAllSecurityCompany");
}

function create(item) {
  const param = {
    NameCompany: item.nameCompany,
    Cost: parseFloat(item.cost),
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    NameCompany: item.nameCompany,
    Cost: parseFloat(item.cost),
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const securityCompanyService = {
  getAll,
  filter,
  remove,
  create,
  update,
};
