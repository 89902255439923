<template>
  <div>
    <div class="col-md-12 pt-0">
      <div class="box-chofer enCurso d-flex">
        <div class="col-md-3 ptop-5">
          <v-skeleton-loader
            v-if="loading"
            type="list-item-two-line"
            height="60"
          />
        </div>
        <div class="col-md-2 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item" height="60" />
        </div>
        <div class="col-md-5 ptop-5">
          <div id="chart" class="float-left">
            <v-skeleton-loader
              style="margin-top:12px"
              v-if="loading"
              type="avatar"
            />
          </div>
          <div class="">
            <v-skeleton-loader
              v-if="loading"
              type="list-item-two-line"
              height="60"
            />
          </div>
        </div>
        <div class="col-md-2 ptop-5">
          <v-skeleton-loader v-if="loading" type="list-item" height="60" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RutaMonitoringSkeleton",
  props: {
    loading: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
