<template>
  <div>
    <div class="row shipping-monitoring ">
      <div class="col-md-6 ">
        <div>
          <div :class="getClass(assigned.estate.id)">
            {{ assigned.orden }}
          </div>
          <b class="fs-15">{{ $t("Remito") }} # {{ assigned.remito }}</b>
        </div>
        <span>{{ assigned.estate.name }}</span>
      </div>

      <div class="col-md-6">
        <div class="float-right" style="padding-top:10px">
          <a :href="pdfUrl" target="_blank" class="mr-5" title="Descargar PDF"
            ><font-awesome-icon
              v-if="pdfUrl != null"
              icon="download"
              class="cursor"
          /></a>
          <font-awesome-icon
            v-if="!show_detail"
            icon="chevron-down"
            @click="show_detail = !show_detail"
            class="cursor"
          />
          <font-awesome-icon
            v-else
            icon="chevron-up"
            @click="show_detail = !show_detail"
            class="cursor"
          />
        </div>
      </div>
    </div>

    <div v-if="show_detail" class="shipping-monitoring-info ">
      <div class="row">
        <div class="col-md-3">
          <v-input :messages="[assigned.client.nameClient]">
            {{ $t("Cliente") }}
          </v-input>
        </div>
        <div class="col-md-5">
          <v-input :messages="[assigned.subClient.nameSubClient]">
            Sub {{ $t("Cliente") }}
          </v-input>
        </div>
        <div class="col-md-4">
          <v-input :messages="[assigned.subClient.subClientAddress]">
            {{ $t("Direccion") }}
          </v-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <v-input :messages="[assigned.pallets.toString()]">
            {{ $t("Palets") }}
          </v-input>
        </div>
        <div class="col-md-3">
          <v-input :messages="[assigned.packages.toString()]">
            Bultos
          </v-input>
        </div>
        <div class="col-md-3">
          <v-input :messages="[assigned.kg.toString()]">
            Kg
          </v-input>
        </div>
        <div class="col-md-3">
          <v-input :messages="[assigned.cubicMeters.toString()]">
            M<sup>3</sup>
          </v-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { remitosService } from "@/libs/ws/remitosService";
export default {
  name: "ShippingMonitoring",
  props: {
    assigned: Object,
    ruta: Object,
  },
  data() {
    return {
      show_detail: false,
      pdfUrl: null,
    };
  },
  mounted() {
    this.getPDF(this.assigned.id);
  },
  updated() {},
  methods: {
    getPDF(id) {
      remitosService
        .getPDF(id)
        .then((response) => {
          this.pdfUrl = response.urlPDFRemito;
        })
        .catch((e) => {
          console.log("error pdf");
          console.log(e);
        });
    },
    getClass(estado) {
      switch (estado) {
        case 1:
          return "circle-monitoring bg-autorizado";
        case 2:
          return "circle-monitoring bg-retenido";
        case 3:
          return "circle-monitoring bg-no-entregado";
        case 4:
          return "circle-monitoring bg-entregado";
        case 5:
          return "circle-monitoring bg-despachado";
        case 6:
          return "circle-monitoring bg-en-camino";
        default:
          return "circle-monitoring bg-en-camino";
      }
    },
  },
};
</script>
