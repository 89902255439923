import { genericService } from "./genericService";
import { akeronService } from "./akeronService";
const section = "AgendaViajes";

function filter(param, from = 0, limit = 10000) {
  return akeronService
    .make_post_request(section + "/ListAgendaViajes", {
      jtStartIndex: from,
      jtPageSize: limit,
      FechaDesde: param.fecha_desde,
      FechaHasta: param.fecha_hasta,
      IdCliente: param.client_id,
    })
    .then((response) => {
      let result = {
        quantity: response.quantity,
        list: response["agendas"],
      };
      return result;
    });
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    fecha: item.fecha + "T" + item.time + ":00-03:00", //"2023-11-10T11:00:00-03:00"
    cliente: item.cliente.id,
    observaciones: item.observaciones,
    Images: item.images,
    UsersComunication: item.usersComunication.map(user=> user.id)
  };

  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    fecha: item.fecha + "T" + item.time + ":00-03:00", //"2023-11-10T11:00:00-03:00"
    cliente: item.cliente.id,
    observaciones: item.observaciones,
    UsersComunication: item.usersComunication.map(user=> user.id)

  };
  return genericService.update(section, param);
}

function addImages(item) {
  const param = {
    Id: item.ruta,
    Images: item.images,
    cliente: item.cliente_id
  };
  return akeronService.make_post_request(section + "/AddImage", param).then((response) => {
    return response;
  });
}

function removeImage(item) {
  const param = {
    IdImage: item.image_id,
    IdAgenda: item.agenda_id,
  };
  return akeronService.make_delete_request(section + "/DeleteImageAgendaViajes", param).then((response) => {
    return response;
  });
}

export const agendaService = {
  filter,
  remove,
  create,
  update,
  removeImage,
  addImages
};
