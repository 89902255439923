<template>
  <v-card @mouseenter="handleExpandMenu(true)" @mouseleave="handleExpandMenu(false)" height="100vh" width="256" class="mx-auto">
    <v-navigation-drawer v-model="$store.state.sidebar.drawer" :width="240" dark app :expand-on-hover="!$store.state.sidebar.open" class="rounded-0">
      <div>
        <v-list style="height:100px">
          <v-list-item>
            <v-list-item-content>
              <v-img v-show="expandMenu" transition="fade-transition" :src="require('@/assets/images/logo.png')" contain max-width="125" class="pt-8" />

              <v-img v-show="!expandMenu" transition="fade-transition" :src="require('@/assets/images/logo-mini.png')" contain height="60" class="pt-8" />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider class="mt-0"></v-divider>
      </div>

      <div style="scroll-margin-top: 100px;">
        <p class="title-sidebar" v-if="expandMenu && items.length > 0">Distribucion</p>
        <p class="title-sidebar" v-if="!expandMenu && items.length > 0">D...</p>
        <v-list v-for="item in items" :key="item.title" dense nav>
          <v-list-group v-if="item.items.length > 0" v-model="item.active" :key="item.title + Math.random()" no-action>
            <v-list-item slot="activator">
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <router-link v-for="subItem in item.items" :key="subItem.title" :to="'/' + subItem.path">
              <v-list-item ripple @click="close" class="subitem">
                <v-list-item-icon>
                  <font-awesome-icon :icon="subItem.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list-group>

          <router-link v-else :to="'/' + item.path">
            <v-list-item :key="item.title" link>
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>

        <p class="title-sidebar" v-if="expandMenu && items_contendores.length > 0">Contenedores</p>
        <p class="title-sidebar" v-if="!expandMenu && items_contendores.length > 0">C...</p>
        <v-list v-for="item in items_contendores" :key="item.title + Math.random()" dense nav>
          <v-list-group v-if="item.items.length > 0" v-model="item.active" :key="item.title" no-action>
            <v-list-item slot="activator">
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <router-link v-for="subItem in item.items" :key="subItem.title" :to="'/' + subItem.path">
              <v-list-item ripple @click="close" class="subitem">
                <v-list-item-icon>
                  <font-awesome-icon :icon="subItem.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list-group>

          <router-link v-else :to="'/' + item.path">
            <v-list-item :key="item.title" link>
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>

        <p class="title-sidebar" v-if="expandMenu && items_general.length > 0">General</p>
        <p class="title-sidebar" v-if="!expandMenu && items_general.length > 0">G...</p>
        <v-list v-for="item in items_general" :key="item.title" dense nav>
          <v-list-group v-if="item.items.length > 0" v-model="item.active" :key="item.title" no-action>
            <v-list-item slot="activator">
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <router-link v-for="subItem in item.items" :key="subItem.title" :to="'/' + subItem.path">
              <v-list-item ripple @click="close" class="subitem">
                <v-list-item-icon>
                  <font-awesome-icon :icon="subItem.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list-group>

          <router-link v-else :to="'/' + item.path">
            <v-list-item :key="item.title" link>
              <v-list-item-icon>
                <font-awesome-icon :icon="item.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import Constant from "@/constants/";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
export default {
  data() {
    return {
      isExternal: null,
      itemsAdminGeneral: [
        {
          title: this.$t("Administrar"),
          icon: "cog",
          active: false,
          items: [
            {
              title: this.$t("Clientes"),
              icon: "users",
              active: false,
              path: "clients",
              items: [],
            },
            {
              title: this.$t("Choferes"),
              icon: "truck",
              active: false,
              path: "drivers",
              items: [],
            },
            {
              title: this.$t("Vehiculos"),
              icon: "truck-moving",
              path: "vehicles",
            },
            { title: this.$t("Zonas"), icon: "map-marker", path: "zones" },
            { title: this.$t("Clusters"), icon: "crosshairs", path: "cluster" },
            { title: this.$t("Tipo_contratacion"), icon: "pager", path: "trips" },
            {
              title: this.$t("Tipo_viaje"),
              icon: "map-marked-alt",
              path: "destinations",
            },
            { title: this.$t("Origen") + " - " + this.$t("Destinos"), icon: "map-pin", path: "origin" },
            { title: "Warehouse", icon: "warehouse", path: "warehouses" },
            { title: this.$t("Cargas"), icon: "truck-loading", path: "loads" },
            { title: this.$t("Pagos"), icon: "tag", path: "payments" },
            {
              title: this.$t("Transportes"),
              icon: "dolly",
              path: "transportation",
            },
            {
              title: this.$t("Tarifas_de_compra"),
              icon: "clipboard-list",
              path: "purchase-fees",
            },
            {
              title: this.$t("Tarifas_de_venta"),
              icon: "clipboard-list",
              path: "rate-sale",
            },
            {
              title: this.$t("Empresas_seguridad"),
              icon: "shield-alt",
              path: "security-companies",
            },
            { title: this.$t("Roles"), icon: "user-cog", path: "roles" },
            { title: this.$t("Usuarios"), icon: "users", path: "users" },
          ],
        },
      ],
      itemsAdminContenedores: [
        {
          title: this.$t("Monitoreo"),
          icon: "desktop",
          active: false,
          items: [
            {
              title: "Rutas",
              icon: "road",
              active: false,
              path: "containers/monitoring",
              items: [],
            },
            {
              title: "Choferes",
              icon: "truck",
              active: false,
              path: "containers/drivermonitoring",
              items: [],
            },
          ],
        },
        {
          title: this.$t("Rutas"),
          icon: "road",
          active: false,
          path: "containers/routes",
          items: [],
        },
        {
          title: this.$t("Reportes"),
          icon: "file-excel",
          active: false,
          path: "containers/reports",
          items: [],
        },
      ],
      itemsAdminDistribucion: [
        {
          title: this.$t("Monitoreo"),
          icon: "desktop",
          active: false,
          items: [
            {
              title: "Rutas",
              icon: "road",
              active: false,
              path: "monitoring",
              items: [],
            },
            {
              title: "Choferes",
              icon: "truck",
              active: false,
              path: "drivermonitoring",
              items: [],
            },
          ],
        },
        {
          title: this.$t("Rutas"),
          icon: "road",
          active: false,
          path: "routes",
          items: [],
        },
        {
          title: this.$t("Pedidos"),
          icon: "sticky-note",
          active: false,
          path: "pedidos",
          items: [],
        },
        {
          title: this.$t("Remitos"),
          icon: "receipt",
          active: false,
          path: "deliverynotes",
          items: [],
        },
        {
          title: this.$t("Ruteo"),
          icon: "route",
          active: false,
          path: "routing",
          items: [],
        },

        {
          title: this.$t("Reportes"),
          icon: "file-excel",
          active: false,
          path: "reports",
          items: [],
        },
        {
          title: "KPI",
          icon: "chart-bar",
          active: false,
          path: "kpi",
          items: [],
        },
        {
          title: "Agenda",
          icon: "calendar-alt",
          active: false,
          path: "calendar",
          items: [],
        },
      ],
      itemsCliente: [
        {
          title: this.$t("Monitoreo"),
          icon: "desktop",
          active: false,
          path: "monitoring",
          items: [],
        },
        {
          title: this.$t("Remitos"),
          icon: "receipt",
          active: false,
          path: "deliverynotes",
          items: [],
        },
        {
          title: this.$t("Reportes"),
          icon: "file-excel",
          active: false,
          path: "reports",
          items: [],
        },
      ],
      itemsClienteContenedores: [
        {
          title: this.$t("Monitoreo"),
          icon: "desktop",
          active: false,
          path: "containers/monitoring",
          items: [],
        },
      ],
      itemsProveedorContenedores: [
      {
          title: this.$t("Monitoreo"),
          icon: "desktop",
          active: false,
          path: "containers/monitoring",
          items: [],
        },
        {
          title: this.$t("Rutas"),
          icon: "road",
          active: false,
          path: "containers/routes",
          items: [],
        },
      ],
      itemsProveedorDistribucion: [
      {
          title: this.$t("Monitoreo"),
          icon: "desktop",
          active: false,
          path: "monitoring",
          items: [],
        },
        {
          title: this.$t("Rutas"),
          icon: "road",
          active: false,
          path: "routes",
          items: [],
        },
      ],
      items: [],
      items_contendores: [],
      items_general: [],
      right: null,
      expandMenu: this.$store.state.sidebar.open ? true : false,
      isMobile: false,
      show: true,
    };
  },
  mounted() {
    this.loadSideBarExternal();
  },
  watch: {
    "$store.state.sidebar.open": function() {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = false;
      }
    },
  },
  methods: {
    handleExpandMenu(value) {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = value;
      }
    },
    close() {},
    loadSideBarExternal() {
      this.userType = cryptoUtils.decrypt(localStorage.getItem("external"), Constant.SECRET_PHRASE);
      if (this.userType != null && this.userType == Constant.USER_TYPE_INTERNAL) {
        this.items = this.itemsAdminDistribucion;
        this.items_general = this.itemsAdminGeneral;
        this.items_contendores = this.itemsAdminContenedores;
      } else if (this.userType != null && this.userType == Constant.USER_TYPE_EXTERNAL_CLIENT){
        this.items = this.itemsCliente;
        this.items_contendores = this.itemsClienteContenedores;
      } else if (this.userType != null && this.userType == Constant.USER_TYPE_EXTERNAL_PROVEEDOR){
        this.items = this.itemsProveedorDistribucion;
        this.items_contendores = this.itemsProveedorContenedores;
      }
    },
  },
};
</script>
