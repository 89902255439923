<template>
  <div>
    <div class="col-md-12 pt-0">
      <div :class="boxClass()" @click="goToDetailRuta()">
        <div class="col-md-1 subBoxChofer">
          <p class="mb-0">
            <b>#{{ ruta.numTrip }}</b>
          </p>
        </div>
        <div class="col-md-2 subBoxChofer">
          <p class="mb-0">
            <b>{{ ruta.driver.nameDriver }}</b>
          </p>
          <span style="text-transform: uppercase;">{{
            ruta.vehicle.patent
          }}</span>
        </div>
        <div class="col-md-3 subBoxChofer">
          <p class="mb-0">
            <b>{{ $t("Origen") }}</b>
          </p>
          <span style="text-transform: uppercase;">{{
            ruta.origen.dirOrigen
          }}</span>
        </div>
        <!--<div class="col-md-2 subBoxChofer">
          <b style="text-transform: capitalize;">{{ruta.name}}</b>
        </div>-->

        <div class="col-md-3">
          <div id="chart" class="float-left">
            <apexchart
              v-if="ruta.delivered.length - getEntregados(ruta) != 0"
              ref="Chart01"
              type="donut"
              width="90"
              height="90"
              :options="chart01Options"
              :series="series01"
            />
            <apexchart
              v-else
              ref="Chart01"
              type="donut"
              width="90"
              height="90"
              :options="chart02Options"
              :series="series01"
            />
          </div>

          <div class="ptop-10">
            <p class="mb-0">
              <b>{{ getEntregados(ruta) }}</b> {{ $t("Entregados") }}
            </p>
            <p class="mb-0">
              <b>{{ getNoEntregados(ruta) }}</b> {{ $t("No_entregados") }}
            </p>
            <span v-if="isEnEspera()"
              ><b>{{ getEnCamino(ruta) }} </b> {{ $t("Despachado") }}</span
            >
            <span v-else
              ><b>{{ getEnCamino(ruta) }} </b> {{ $t("En_camino") }}</span
            >
          </div>
        </div>
        <div class="col-md-3 subBoxChofer">
          <div class="row">
            <div class="col-md-7">
              <p class="mb-0 text-center"><b>Hora inicio | Fin</b></p>
              <span>
                <p class="text-center">
                  {{
                    (ruta.initialCharge != null
                      ? getHora(new Date(ruta.initialCharge))
                      : "") +
                      " | " +
                      (ruta.finishedCharge != null
                        ? getHora(new Date(ruta.finishedCharge))
                        : "")
                  }}
                </p></span
              >
            </div>
            <div class="col-md-5 subBoxChofer pl-0">
              <b v-if="sinRemitos()" class="state_en_espera">{{
                $t("En_espera")
              }}</b>
              <b v-else-if="isComplete()" class="state_entregado">{{
                $t("Completada")
              }}</b>
              <b v-else-if="isEnCurso()" class="state_en_camino">{{
                $t("En_curso")
              }}</b>
              <b v-else-if="isEnEspera()" class="state_en_espera">{{
                $t("En_espera")
              }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RutaMonitoring",
  props: {
    ruta: Object,
  },
  data() {
    return {
      series01: [],
      chart02Options: {},
      chart01Options: {
        stroke: { show: false },
        colors: ["#f23d4f", "#d4d4d4"],
        legend: { show: false },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: { offsetY: -20 },
                value: {
                  fontSize: 16,
                  offsetY: -10,
                  color: "#f23d4f",
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "",
                  formatter: function(w) {
                    var result = 0;

                    if (
                      parseInt(w.globals.series[0]) +
                        parseInt(w.globals.series[1]) ==
                      0
                    ) {
                      result = 0;
                    } else {
                      result = Math.trunc(
                        (w.globals.series[0] * 100) /
                          (w.globals.series[0] + w.globals.series[1])
                      );
                    }

                    return result + "%";
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {
    rutaUpdate() {
      return this.ruta;
    },
  },
  watch: {},
  mounted() {
    var aux = Object.assign({}, this.chart01Options);
    aux.colors = ["#d4d4d4", "#d4d4d4"];
    aux.plotOptions.pie.donut.labels.value.color = "#d4d4d4";
    this.chart02Options = Object.assign({}, aux);

    this.series01 = [
      parseInt(this.getEntregados(this.ruta)) +
        parseInt(this.getNoEntregados(this.ruta)),
      parseInt(this.getEnCamino(this.ruta)),
    ];
  },
  methods: {
    goToDetailRuta() {
      this.$router.push("/monitoring/route/" + this.ruta.id);
    },
    isComplete() {
      //if (this.ruta.delivered.length == this.getEntregados(this.ruta)) {
      if (this.ruta.finishedTrip != null) {
        return true;
      } else {
        return false;
      }
    },
    sinRemitos() {
      if (this.ruta.delivered.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    isEnCurso() {
      if (!this.isComplete() && this.ruta.initialDate != null) {
        return true;
      } else {
        return false;
      }
    },
    isEnEspera() {
      if (!this.isComplete() && this.ruta.initialDate == null) {
        return true;
      } else {
        return false;
      }
    },
    boxClass() {
      if (this.isComplete() && this.ruta.delivered.length != 0) {
        return "box-chofer complete row";
      } else {
        return "box-chofer enCurso row";
      }
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },

    getEntregados(ruta) {
      var contador = 0;
      if (ruta.delivered.length == 0) {
        return 0;
      }
      ruta.delivered.forEach(function(elemento) {
        if (elemento.closed && elemento.delivered != null) {
          contador++;
        }
      });
      return contador;
    },
    getNoEntregados(ruta) {
      var contador = 0;
      if (ruta.delivered.length == 0) {
        return 0;
      }
      ruta.delivered.forEach(function(elemento) {
        if (elemento.closed == true && elemento.delivered == null) {
          contador++;
        }
      });
      return contador;
    },
    getEnCamino(ruta) {
      var contador = 0;
      if (ruta.delivered.length == 0) {
        return 0;
      }
      ruta.delivered.forEach(function(elemento) {
        if (elemento.closed != true) {
          contador++;
        }
      });
      return contador;
    },
  },
};
</script>
