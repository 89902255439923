<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="600px">
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>

                <v-text-field data-vv-as="Desde" label="Desde" name="Desde" v-model="item.desde" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("Desde") }}</span>

                <v-text-field data-vv-as="Hasta" label="Hasta" name="Hasta" v-model="item.hasta" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("Hasta") }}</span>

                <v-combobox
                  v-model="item.measurementUnitType"
                  data-vv-as="Tipo de medida"
                  name="measurementUnitType"
                  item-text="name"
                  item-value="id"
                  :items="measurementUnitTypes"
                  label="Tipo de medida"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("measurementUnitType") }}</span>

                <v-text-field
                  hint="El campo porcentaje solo sera utilizado en el caso de seleccionar tipo de medida 'Valor declarado'"
                  :persistent-hint="true"
                  data-vv-as="Porcentaje"
                  label="Porcentaje"
                  name="Porcentaje"
                  v-model="item.porecentaje"
                  v-validate="'decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("Porcentaje") }}</span>

                <v-text-field data-vv-as="Precio" label="Precio" name="Precio" v-model="item.price" v-validate="'required|decimal'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("Precio") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn v-if="!isViewModal()" color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      measurementUnitTypes: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadmeasurementUnitTypes();
  },
  methods: {
    loadmeasurementUnitTypes() {
      this.service
        .getAllMeasurement()
        .then((response) => {
          this.measurementUnitTypes = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.service
              .updateItem(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.service
              .createItem(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
