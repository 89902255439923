<script>
import Constant from "@/constants/";
export default {
  data() {
    return {
      list_access: true,
      create_access: true,
      update_access: true,
      delete_access: true,
      sensible_access: false,
    };
  },
  mounted() {
    this.getAccessArray();
  },
  methods: {
    getAccessArray() {
      var rol = JSON.parse(this.CryptoJS.AES.decrypt(localStorage.getItem("rol"), Constant.SECRET_PHRASE).toString(this.CryptoJS.enc.Utf8));
      var path = this.$route.path.replace("/", "");

      rol.modulos.forEach((element) => {
        if (element.module.controllerName == path) {
          this.list_access = element.list;
          this.create_access = element.create;
          this.update_access = element.update;
          this.delete_access = element.delete;
          this.sensible_access = element.sensible;
        }
      });
    },
  },
};
</script>
