<template>
  <GmapMap
    :center="getCenter()"
    :zoom="8"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      disableDefaultUi: true,
    }"
    map-type-id="terrain"
    style="width: 100%; height: 250px"
  >
    <div v-for="remito of remitos" :key="remito.id">
      >
      <GmapMarker
        v-if="remito.subClient.latitudAddress != null && remito.subClient.longitudAddress != null"
        :position="getPosition(remito.subClient.latitudAddress, remito.subClient.longitudAddress)"
        :icon="getIcon(remito)"
      >
      </GmapMarker>
      <!-- 
      <GmapInfoWindow>
        <div style="padding:5px">
          <span class="fs-15">{{ 12 }}</span>
        </div>
      </GmapInfoWindow> -->
    </div>
    <!--  <GmapMarker
      v-if="driver.latitud != null && driver.longitud != null"
      :position="getPosition(driver.latitud, driver.longitud)"
      :icon="require('@/assets/images/marker-truck.png')"
    >
    </GmapMarker> -->
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  name: "GeoMultipleMarkers",

  components: {},
  props: {
    remitos: Array,
    remitos_seleccionados: Array,
  },
  data() {
    return {
      lngMin: null,
      latMin: null,
      lngMax: null,
      latMax: null,
    };
  },
  computed: {
    google: gmapApi,
    remitosObject() {
      return this.remitos;
    },
  },
  mounted() {},
  methods: {
    getCenter() {
      var lat = (this.latMax + this.latMin) / 2;
      var lng = (this.lngMax + this.lngMin) / 2;
      return { lat: lat, lng: lng };
    },
    getIcon(remito) {
      if (this.remitos_seleccionados.indexOf(remito) >= 0) {
        return require("@/assets/images/geo/verde.svg");
      } else {
        return require("@/assets/images/geo/rojo.svg");
      }
    },
    getPosition(lat, lng) {
      if (this.latMax == null || this.latMax < parseFloat(lat)) {
        this.latMax = parseFloat(lat);
      }

      if (this.latMin == null || this.latMin > parseFloat(lat)) {
        this.latMin = parseFloat(lat);
      }

      if (this.lngMax == null || this.lngMax < parseFloat(lng)) {
        this.lngMax = parseFloat(lng);
      }

      if (this.lngMin == null || this.lngMin > parseFloat(lng)) {
        this.lngMin = parseFloat(lng);
      }

      this.center = this.getCenter();

      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    },
  },
};
</script>
