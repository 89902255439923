import { genericService } from "./genericService";

const section = "Origin";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "origenes", name, from, limit, active);
}

function getAll() {
  return genericService.get("Origin/ListAllOrigin");
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    Origen: item.origen,
    OriginAddress: item.originAddress,
    IdLocality: item.locality.id,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    Origen: item.origen,
    OriginAddress: item.originAddress,
    Observaciones1: "",
    Observaciones2: "",
    IdLocality: item.locality.id,
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const originService = {
  getAll,
  filter,
  remove,
  create,
  update,
};
