import { genericService } from "./genericService";
import { akeronService } from "./akeronService";
import { remitosService } from "./remitosService";

const section = "Pedido";

function filter(param, from = 0, limit = 10) {
  return akeronService
    .make_post_request(section + "/ListPedidos", {
      jtStartIndex: from,
      jtPageSize: limit,
      FechaDesde: param.fecha_desde,
      FechaHasta: param.fecha_hasta,
      IdCliente: param.client_id,
      IdEstado: param.Estado,
      Pedido: param.NumPedido,
      ConRemito: param.ConRemito,
    })
    .then((response) => {
      let result = {
        quantity: response.quantity,
        list: response["remitos"],
      };
      return result;
    });
}

function create(item) {
  const param = {
    IdClient: item.client.id,
    IdSubClient: item.subClient.id,
    Pedido: item.pedido,
    Observation: item.observation,
    declaredValue: parseInt(item.declaredValue),
    Pallets: parseFloat(item.pallets),
    Packages: parseFloat(item.packages),
    Kg: parseFloat(item.kg),
    CubicMeters: parseFloat(item.cubicMeters),
    Rack: parseFloat(item.rack),
    Bobina: parseFloat(item.bobina),
    Tambores: parseFloat(item.tambores),
    codEstate: remitosService.AUTORIZADO,
  };
  return genericService.createV2(section + "/CreatePedido", param);
}

function update(item) {
  const param = {
    Id: item.id,
    IdClient: item.client.id,
    IdSubClient: item.subClient.id,
    Remito: item.remito,
    Pedido: item.pedido,
    Observation: item.observation,
    declaredValue: parseInt(item.declaredValue),
    Pallets: parseFloat(item.pallets),
    Packages: parseFloat(item.packages),
    Kg: parseFloat(item.kg),
    CubicMeters: parseFloat(item.cubicMeters),
    Rack: parseFloat(item.rack),
    Bobina: parseFloat(item.bobina),
    Tambores: parseFloat(item.tambores),
    codEstate: remitosService.AUTORIZADO,
    IdTrip: 0,
    Orden: 0,
  };
  return genericService.updateV2(section + "/UpdatePedido", param);
}

function createMassiveInBag(item, type) {
  if (type == 0) {
    //basico
    return genericService.createV2(section + "/CreatePedidoInBagMasive", item);
  } else {
    //type == 1 completa
    return genericService.createV2(section + "/CreatePedidoInBagMasiveV2", item);
  }
}

function remove(id) {
  return genericService.removeV2(section + "/DeletePedido", id);
}

export const pedidosService = {
  createMassiveInBag,
  filter,
  create,
  update,
  remove,
};
