import { genericService } from "./genericService";
import { akeronService } from "./akeronService";

function getAll() {
  return genericService.get("Country/ListAllCountry");
}

function getAllProvince(country) {
  return genericService.get("Province/ListAllProvince/" + country);
}

function getAllLocality(province) {
  return genericService.get("Locality/ListAllLocality/" + province);
}

function getProvinceDeliveryPending() {
  return genericService.get("Province/ProvinceWithDeliveryPending/");
}
function getLocalityDeliveryPending(id) {
  var array = [];
  array.push(id);
  return akeronService
    .make_post_request("Locality/LocalityWithDeliveryPending", {
      provinces: array,
    })
    .then((response) => {
      return response;
    });
}

export const countryService = {
  getAll,
  getAllProvince,
  getAllLocality,
  getProvinceDeliveryPending,
  getLocalityDeliveryPending,
};
