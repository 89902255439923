import { genericService } from "./genericService";

const section = "User";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "users", name, from, limit, active);
}

function getAll() {
  return genericService.get("User/ListAllUser");
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item, user_type_selected) {
  let param = {};

  let url = "";
  if(user_type_selected.id == 1 || user_type_selected.id == 2) {
    url = section
    param = {
      Name: item.name,
      Username: item.username,
      Password: item.password,
      Email: item.email,
      MobileNumber: item.mobileNumber,
      IsActive: item.isActive,
      IdRol: item.rol.id,
      isExternal: item.isExternal,
      idCompanyExternal: item.isExternal ? item.companyExternal.id : 0,
    };
    
    return genericService.create(url, param);
    
  } else if (user_type_selected.id == 3) {
    url = "User/CreateUserProveedorExt";
    param = {
      Name: item.name,
      Username: item.username,
      Password: item.password,
      Email: item.email,
      MobileNumber: item.mobileNumber,
      IsActive: item.isActive,
      IdRol: item.rol.id,
      idCompanyExternal: item.companyExternalTransport.id,
    }
    return genericService.createV2(url, param);
  }

  return "";
}

function update(item, user_type_selected) {
  let param = {};
  let url = "";

  if(user_type_selected.id == 1 || user_type_selected.id == 2) {
    url = section;
    param = {
      id: item.id,
      Name: item.name,
      Username: item.username,
      Password: item.password == "" ? null : item.password,
      Email: item.email,
      MobileNumber: item.mobileNumber,
      IsActive: item.isActive,
      IdRol: item.rol.id,
      isExternal: item.isExternal,
      idCompanyExternal: item.isExternal ? item.companyExternal.id : 0,
      OrganizationBaseId: item.organizationBaseId
    }

    return genericService.update(url, param);

  } else if (user_type_selected.id == 3) {
    url = "User/UpdateUserProveedorExt";
    param = {
      id: item.id,
      Name: item.name,
      Username: item.username,
      Password: item.password == "" ? null : item.password,
      Email: item.email,
      MobileNumber: item.mobileNumber,
      IsActive: item.isActive,
      IdRol: item.rol.id,
      idCompanyExternal: item.isExternal ? item.companyExternalTransport.id : 0,
    }

    return genericService.updateV2(url, param);

  }

  return "";
}

export const usersService = {
  filter,
  getAll,
  remove,
  create,
  update
};
