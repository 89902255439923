<template>
  <div>
    <v-layout>
      <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="1200px">
        <v-card>
          <v-card-title>
            <span class="headline"> Ruta N#{{ ruta.numTrip }}</span>
          </v-card-title>

          <v-card-text>
            <div>
              <v-data-table
                :headers="headersRemitos"
                :items="ruta.delivered"
                class="elevation-1 mt-5"
                v-model="selected"
                :loading="loadingRemitos"
                show-select
                :single-select="false"
                :disable-pagination="true"
                :hide-default-footer="true"
              >
                <template v-slot:item="props">
                  <tr>
                    <td>
                      <v-checkbox :input-value="props.isSelected" style="margin:0px;padding:0px" hide-details @change="props.select($event)" />
                    </td>
                    <td>{{ props.item.orden }}</td>
                    <td>{{ props.item.remito }}</td>
                    <td>{{ props.item.client.nameClient }}</td>
                    <td>{{ props.item.subClient.nameSubClient }}</td>
                    <td>
                      {{ props.item.subClient.subClientAddress != null ? props.item.subClient.subClientAddress : "" }}
                    </td>
                    <td>{{ props.item.pallets }}</td>
                    <td>{{ props.item.packages }}</td>
                    <td>{{ props.item.kg }}</td>
                    <td>{{ props.item.cubicMeters }}</td>

                    <td
                      style="min-width:135px"
                      v-html="getState(props.item, props)"
                      :title="
                        props.item.closed == true && props.item.delivered != null ? new Date(props.item.delivered).toISOString().substr(0, 10) + ' | ' + getHora(new Date(props.item.delivered)) : ''
                      "
                    ></td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text v-if="formBtn != '' && formBtn != 'Crear'" type="generateReport" @click="generateReport">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { rutasService } from "@/libs/ws/rutasService";

export default {
  props: {
    modal_title: String,
    dialog: Boolean,
    button_name: String,
    formBtn: String,
    ruta: Object,
    selectedRemitos: Function,
  },
  components: {},
  data() {
    return {
      reportGenerate: false,
      selected: [],
      show: false,
      clients: [],
      subclients: [],
      loading_subclientes: false,
      search: " ",
      headersRemitos: [
        {
          text: "Orden",
          align: "left",
          sortable: true,
          value: "Orden",
          class: "lighten-2",
        },
        {
          text: "N° Remito",
          align: "left",
          sortable: true,
          value: "Remito",
          class: "lighten-2",
        },

        {
          text: "Cliente",
          align: "left",
          sortable: true,
          value: "clients.nameClient",
          class: "lighten-2",
        },
        {
          text: "Sub Cliente",
          align: "left",
          sortable: true,
          value: "subclients.nameSubClient",
          class: "lighten-2",
        },
        {
          text: "Direccion",
          align: "left",
          sortable: true,
          value: "subclients.subClientAddress",
          class: "lighten-2",
        },
        {
          text: "Pallets",
          align: "left",
          sortable: true,
          value: "pallets",
          class: "lighten-2",
        },
        {
          text: "Bultos",
          align: "left",
          sortable: true,
          value: "packages",
          class: "lighten-2",
        },
        {
          text: "KG",
          align: "left",
          sortable: true,
          value: "kg",
          class: "lighten-2",
        },
        {
          text: "M3",
          align: "left",
          sortable: true,
          value: "m3",
          class: "lighten-2",
        },
        {
          text: "Estado",
          align: "left",
          sortable: true,
          value: "state",
          class: "lighten-2",
        },
      ],
      loadingRemitos: false,
    };
  },
  watch: {
    dialog() {
      this.errors.clear();
      this.selected = this.ruta.delivered;
      this.getRemitos(this.ruta.id);
    },
  },
  mounted() {
    if (this.ruta != null) {
      this.selected = this.ruta.delivered;
      this.getRemitos(this.ruta.id);
    }
  },

  methods: {
    getRemitos(id) {
      this.loadingRemitos = true;
      rutasService
        .getRutaWithRemitos(id)
        .then((response) => {
          this.ruta.delivered = response.delivered;
          this.selected = response.delivered;
          this.loadingRemitos = false;
        })
        .catch((e) => {
          console.log(e);
          this.loadingRemitos = false;
        });
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },
    getState(item, ruta) {
      if (item.closed == true) {
        if (item.delivered != null) {
          return '<p class="state_entregado">Entregado</p>';
        } else {
          return '<p class="state_no_entregado">No Entregado</p>';
        }
      } else {
        if (ruta != null && ruta.initialDate == null) {
          return '<p class="state_en_espera">En espera</p>';
        }
        return '<p class="state_en_camino">En camino</p>';
      }
    },
    generateReport() {
      this.$emit("selectedRemitos", this.selected);
      this.$emit("updateDialog", false);
    },
  },
};
</script>
