import { parse } from "@fortawesome/fontawesome-svg-core";
import { genericService } from "./genericService";

const section = "PurchaseRate";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "purchaseRates", name, from, limit, active);
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    IdVehicleType: item.vehicleType.id,
    IdChargeType: item.chargeType.id,
    IdZone: item.zone.id,
    BasicCost: parseFloat(item.basicCost),
    AssistantCost: parseFloat(item.assistantCost),
    DelayCost: parseFloat(item.delayCost),
    StayCost: parseFloat(item.stayCost),
    TurnCost: parseFloat(item.turnCost),
    delayDestinationCost: parseInt(item.delayDestinationCost),
    devolutionsAMCost: parseInt(item.devolutionsAMCost),
    devolutionsPMCost: parseInt(item.devolutionsPMCost),
    nigthPlusCost: parseInt(item.nigthPlusCost),
    devolutionReturnCost: parseInt(item.devolutionReturnCost),
    longDistanceCost: parseInt(item.longDistanceCost),
    falseTransportationCost: parseInt(item.falseTransportationCost),
    saveWarehouseCost: parseInt(item.saveWarehouseCost),
    wekendAndHolidayCost: parseInt(item.wekendAndHolidayCost),
    imoCost: parseInt(item.imoCost),
    deviationCost: parseInt(item.deviationCost),
    sameDayReturnCost: parseFloat(item.sameDayReturnCost),
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    IdVehicleType: item.vehicleType.id,
    IdChargeType: item.chargeType.id,
    IdZone: item.zone.id,
    BasicCost: parseFloat(item.basicCost),
    AssistantCost: parseFloat(item.assistantCost),
    DelayCost: parseFloat(item.delayCost),
    StayCost: parseFloat(item.stayCost),
    TurnCost: parseFloat(item.turnCost),
    delayDestinationCost: parseInt(item.delayDestinationCost),
    devolutionsAMCost: parseInt(item.devolutionsAMCost),
    devolutionsPMCost: parseInt(item.devolutionsPMCost),
    nigthPlusCost: parseInt(item.nigthPlusCost),
    devolutionReturnCost: parseInt(item.devolutionReturnCost),
    longDistanceCost: parseInt(item.longDistanceCost),
    falseTransportationCost: parseInt(item.falseTransportationCost),
    saveWarehouseCost: parseInt(item.saveWarehouseCost),
    wekendAndHolidayCost: parseInt(item.saveWarehouseCost),
    imoCost: parseInt(item.imoCost),
    deviationCost: parseInt(item.deviationCost),
    sameDayReturnCost: parseFloat(item.sameDayReturnCost),
    isActive: item.isActive,
  };

  return genericService.update(section, param);
}

function incrementPrice(item) {
  const param = {
    Percentage: parseFloat(item.porcentage),
    PurchaseRates: item.ids,
  };

  return genericService.updateV2(section + "/UpdatePurchaseRateMasive", param);
}

function getBasicCost(IdVehicle, IdTypeCharge, IdZone) {
  return genericService.get("PurchaseRate/FindPurchaseRate/" + IdVehicle + "/" + IdTypeCharge + "/" + IdZone);
}

function FindPurchaseRateNew(IdVehicle, IdTypeCharge, IdZone) {
  return genericService.get("PurchaseRate/FindPurchaseRateNew/" + IdVehicle + "/" + IdTypeCharge + "/" + IdZone);
}

export const purchaseRateService = {
  getBasicCost,
  filter,
  remove,
  create,
  update,
  incrementPrice,
  FindPurchaseRateNew,
};
