import { genericService } from "./genericService";
import { akeronService } from "./akeronService";

const section = "SalesRate";
const sectionItem = "ItemSaleRate";

function getAll() {
  return genericService.get("SalesRate/ListAll");
}

function remove(id) {
  return genericService.removeV2(section + "/DeleteSaleRate", id);
}

function create(item) {
  const param = {
    Name: item.name,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    Name: item.name,
  };
  return genericService.update(section, param);
}

function createItem(item) {
  const param = {
    IdSaleRate: item.idSaleRate,
    IdMeasurementUnitType: item.measurementUnitType.id,
    Desde: parseInt(item.desde),
    Hasta: parseInt(item.hasta),
    Porecentaje: item.porecentaje == "" ? 0 : parseFloat(item.porecentaje),
    Price: parseFloat(item.price),
  };
  return genericService.create(sectionItem, param);
}

function updateItem(item) {
  const param = {
    id: item.id,
    IdSaleRate: item.idSaleRate,
    IdMeasurementUnitType: item.measurementUnitType.id,
    Desde: parseInt(item.desde),
    Hasta: parseInt(item.hasta),
    Porecentaje: item.porecentaje == "" ? 0 : parseFloat(item.porecentaje),
    Price: parseFloat(item.price),
  };
  return genericService.update(sectionItem, param);
}

function removeItem(id) {
  return genericService.remove(sectionItem, id);
}

function getAllMeasurement() {
  return genericService.get("MeasurementUnitType/ListAll");
}

export const rateSaleService = {
  createItem,
  updateItem,
  removeItem,
  getAllMeasurement,
  getAll,
  remove,
  create,
  update,
};
