<template>
  <div style="padding: 4px">
    <div class="row mt-1">
      <div class="col-md-4">
        <v-combobox
          dense
          v-model="adicional.quantity"
          data-vv-as="Cantidad"
          name="quantity"
          :items="cmbFiller"
          :label="$t(adicional.tipo)"
          autocomplete="off"
          v-validate="'required'"
          @change="calculateCost()"
          :disabled="isViewModal"
        ></v-combobox>
      </div>
      <div class="col-md-3">
        <v-text-field
          dense
          v-model="adicional.cost"
          label="Costo unitario"
          outlined
          disabled
        ></v-text-field>
      </div>
      <div class="col-md-3">
        <v-text-field
          dense
          :value="adicional_total"
          label="Importe Total"
          outlined
          disabled
        ></v-text-field>
      </div>
      <div class="col-md-2">
        <v-btn
          small
          color="red"
          icon
          class=" mt-1 white--text"
          @click="$emit('delete_adicional', adicional)"
        >
          <v-icon dark>
            clear
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    adicional: Object,
    delete_adicional: Function,
    index: Number,
    setQuantity: Function,
    isViewModal: Boolean,
  },
  components: {},
  data() {
    return {
      adicional_total: 0,
      cmbFiller: [1, 2, 3, 4, 5],
    };
  },
  watch: {
    adicional() {
      this.calculateCost();
    },
  },
  mounted() {
    if (this.adicional == null) {
      this.calculateCost();
      this.$emit("setQuantity", this.index, this.adicional.quantity);
    }
    this.calculateCost();
  },

  methods: {
    calculateCost() {
      this.adicional_total = this.adicional.quantity * this.adicional.cost;
      this.$emit("setQuantity", this.index, this.adicional.quantity);
    },
  },
};
</script>
