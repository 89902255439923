<template>
  <div>
    <Title :pages="[{ icon: 'chart-bar', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid v-if="list_access">
      <v-form>
        <div class="row filter justify-content-md-center mt-10">
          <div class="col-md-5 ">
            <div class="row" style="justify-content:center ; display: flex ; text-align: center">
              <div class="col-md-12" style="justify-content:center ; display: flex ; text-align: center">
                <v-combobox label="Tipo de informe" name="tipodeinforme" :items="tipoInformes" v-model="rtaTipo" @change="limpiarComboBox()" v-validate="'required'"></v-combobox>
                <span class="red--text">{{ errors.first("tipodeinforme") }}</span>
              </div>
            </div>
            <div class="row" v-if="rtaTipo != 'Total' && rtaTipo != ''">
              <div class="col-md-12">
                <v-combobox
                  :search-input.sync="searchInput"
                  :label="(rtaTipo == 'Remito')?'Cliente':rtaTipo"
                  :items="
                    rtaTipo == 'Chofer'
                      ? drivers
                      : '' || rtaTipo == 'Transporte'
                      ? transporte
                      : '' || rtaTipo == 'Cliente'
                      ? clients
                      : '' || rtaTipo == 'Subcliente'
                      ? subclients
                      : '' || rtaTipo == 'Origen'
                      ? origines
                      : '' || rtaTipo == 'Remito'
                      ? clients
                      : ''
                  "
                  v-model="filtro"
                  :item-text="
                    rtaTipo == 'Chofer'
                      ? 'nameDriver'
                      : '' || rtaTipo == 'Transporte'
                      ? 'nameTransport'
                      : '' || rtaTipo == 'Cliente'
                      ? 'nameClient'
                      : '' || rtaTipo == 'Subcliente'
                      ? 'nameSubClient'
                      : '' || rtaTipo == 'Origen'
                      ? 'dirOrigen'
                      : ''  || rtaTipo == 'Remito'
                      ? 'nameClient'
                      : ''
                  "
                  @keyup="loadSubclients()"
                  :loading="loading_subclientes"
                  item-value="id"
                ></v-combobox>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dateInicio_filter" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateInicio_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dateFin_filter" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateFin_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="row mb-2" style="justify-content:center ; display: flex ; text-align: center">
              <download-excel v-show="false" :data="json_data" worksheet="My Worksheet" :name="'reporte_distribucion_' + rtaTipo.toLowerCase() + '.xls'" ref="refDownload"> </download-excel>
              <v-btn :loading="loading_reporte" @click="validateCampos()">
                Descargar
              </v-btn>
            </div>
          </div>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

import { choferesService } from "@/libs/ws/choferesService";
import { transportService } from "@/libs/ws/transportService";
import { clientService } from "@/libs/ws/clientService";
import { subClientService } from "@/libs/ws/subClientService";
import { reportService } from "@/libs/ws/reportService";
import { originService } from "@/libs/ws/originService";
import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: { Title },
  mixins: [RolMixin],
  data() {
    return {
      param: {
        type: "",
        numType: "",
        fecha_inicio: "",
        fecha_fin: "",
      },
      json_fields_total: {
        fechA_STRING: "fechA_STRING",
        Hoja_Ruta: "hojA_RUTA",
        Orden: "orden",
        Origen: "origen",
        Cliente: "cliente",
        Sub_cliente: "suB_CLIENTE",
        direccioN_SUB_CLIENTE: "direccioN_SUB_CLIENTE",
        localidaD_SUB_CLIENTE: "localidaD_SUB_CLIENTE",
        Remito: "remito",
        estado: "estado",
        Pallets: "pallets",
        Bulto: "bulto",
        kgs: "kgs",
        m3: "m3",
        racks: "racks",
        bobinas: "bobinas",
        tambores: "tambores",
        vr: "pv",
        observaciones: "observaciones",
        zona: "zona",
        chofer: "chofer",
        vehiculo: "vehiculo",
        tipo_VEHICULO: "tipO_VEHICULO",
        carga_MAX: "cargA_MAX",
        tipo_CARGA: "tipO_CARGA",
        tipo_DESTINO: "tipO_DESTINO",
        tipo_VIAJE: "tipO_VIAJE",
        transporte: "transporte",
        emp_CUSTODIA: "emP_CUSTODIA",
        cant_MODULOS: "canT_MODULOS",
        costo_CUSTODIA: "costO_CUSTODIA",
        costo_BASICO: "costO_BASICO",
        cant_PEONES: "canT_PEONES",
        costo_PEON: "costO_PEON",
        cant_DEMORA_DESTINO: "canT_DEMORA_DESTINO",
        costo_DEMORA_DESTINO: "costO_DEMORA_DESTINO",
        cant_DEMORA_ORIGEN: "canT_DEMORA_ORIGEN",
        costo_DEMORA_ORIGEN: "costO_DEMORA_ORIGEN",
        cant_ESTADIA: "canT_ESTADIA",
        costo_ESTADIA: "costO_ESTADIA",
        cant_VUELTAS: "canT_VUELTAS",
        costo_VUELTAS: "costO_VUELTAS",
        canT_DevolutionAM: "canT_DevolutionAM",
        costO_DevolutionAM: "costO_DevolutionAM",
        canT_DevolutionPM: "canT_DevolutionPM",
        costO_DevolutionPM: "costO_DevolutionPM",
        canT_NightPlus: "canT_NightPlus",
        costO_NightPlus: "costO_NightPlus",
        canT_DevolutionReturn: "canT_DevolutionReturn",
        costO_DevolutionReturn: "costO_DevolutionReturn",
        canT_LongDistance: "canT_LongDistance",
        costO_LongDistance: "costO_LongDistance",
        canT_FalseTransportation: "canT_FalseTransportation",
        costO_FalseTransportation: "costO_FalseTransportation",
        canT_SaveWarehouse: "canT_SaveWarehouse",
        costO_SaveWarehouse: "costO_SaveWarehouse",
        canT_WekendAndHoliday: "canT_WekendAndHoliday",
        costO_WekendAndHoliday: "costO_WekendAndHoliday",
        canT_IMO: "canT_IMO",
        costO_IMO: "costO_IMO",
        costo_TOTAL_REMITO: "costO_TOTAL_REMITO",
        tipo: "tipo",
      },
      json_fields_chofer: {
        fechA_STRING: "fechA_STRING",
        hojA_RUTA: "hojA_RUTA",
        orden: "orden",
        origen: "origen",
        cliente: "cliente",
        suB_CLIENTE: "suB_CLIENTE",
        remito: "remito",
        estado: "estado",
        pallets: "pallets",
        bulto: "bulto",
        kgs: "kgs",
        m3: "m3",
        racks: "racks",
        bobinas: "bobinas",
        tambores: "tambores",
        vr: "pv",
        observaciones: "observaciones",
        zona: "zona",
        chofer: "chofer",
        vehiculo: "vehiculo",
        tipO_VEHICULO: "tipO_VEHICULO",
        cargA_MAX: "cargA_MAX",
        tipO_CARGA: "tipO_CARGA",
        tipO_DESTINO: "tipO_DESTINO",
        tipO_VIAJE: "tipO_VIAJE",
        transporte: "transporte",
        emP_CUSTODIA: "emP_CUSTODIA",
        canT_MODULOS: "canT_MODULOS",
        canT_PEONES: "canT_PEONES",
        costo_PEON: "costO_PEON",
        cant_DEMORA_DESTINO: "canT_DEMORA_DESTINO",
        costo_DEMORA_DESTINO: "costO_DEMORA_DESTINO",
        cant_DEMORA_ORIGEN: "canT_DEMORA_ORIGEN",
        costo_DEMORA_ORIGEN: "costO_DEMORA_ORIGEN",
        canT_ESTADIA: "canT_ESTADIA",
        costo_ESTADIA: "costO_ESTADIA",
        canT_VUELTAS: "canT_VUELTAS",
        costo_VUELTAS: "costO_VUELTAS",
        canT_DevolutionAM: "canT_DevolutionAM",
        costO_DevolutionAM: "costO_DevolutionAM",
        canT_DevolutionPM: "canT_DevolutionPM",
        costO_DevolutionPM: "costO_DevolutionPM",
        canT_NightPlus: "canT_NightPlus",
        costO_NightPlus: "costO_NightPlus",
        canT_DevolutionReturn: "canT_DevolutionReturn",
        costO_DevolutionReturn: "costO_DevolutionReturn",
        canT_LongDistance: "canT_LongDistance",
        costO_LongDistance: "costO_LongDistance",
        canT_FalseTransportation: "canT_FalseTransportation",
        costO_FalseTransportation: "costO_FalseTransportation",
        canT_SaveWarehouse: "canT_SaveWarehouse",
        costO_SaveWarehouse: "costO_SaveWarehouse",
        canT_WekendAndHoliday: "canT_WekendAndHoliday",
        costO_WekendAndHoliday: "costO_WekendAndHoliday",
        canT_IMO: "canT_IMO",
        costO_IMO: "costO_IMO",
      },
      json_data: [],
      loading_reporte: false,
      page_title: this.$t("Reportes"),
      tipoInformes: ["Total", "Chofer", "Transporte", "Cliente", "Subcliente", "Origen", "Remito"],
      report: "",      
      rtaTipo: "",
      drivers: "",
      transporte: "",
      clients: "",
      subclients: "",
      origines: "",
      filtro: "",
      refDownload: "",
      dateInicio_filter: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10),
      dateFin_filter: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10),
      searchInput: "",
      loading_subclientes: false,
    };
  },
  mounted() {
    this.loadChoferes();
    this.loadTransport();
    this.loadClients();
    this.loadSubclients();
    this.loadOrigenes();
  },
  methods: {
    loadReporte2(fecha_desde, fecha_hasta, ultimo) {
      this.param.fecha_inicio = fecha_desde;
      this.param.fecha_fin = fecha_hasta;
      return reportService
        .getReport(this.param)
        .then((response) => {
          console.log(response);
          if (!response == "") {
            response.forEach((element) => {
              element.remito = "\xA0" + element.remito;
            });
            this.json_data = this.json_data.concat(response);
          } else {
            return false;
          }
          return true;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
          return false;
        });
    },
    async getDiaxDia() {
      this.loading_reporte = true;
      const date1 = new Date(this.dateInicio_filter);
      const date2 = new Date(this.dateFin_filter);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      var respuesta = false;
      this.json_data = [];
      for (var i = 0; i <= diffDays; i++) {
        var rta = this.addDaysToDate(date1, i);
        respuesta += await this.loadReporte2(rta, rta, i == 0);
      }

      if (respuesta) {
        this.$refs.refDownload.$el.click();
      } else {
        this.$toast.error("No hay datos en los dias solicitados", "Error");
      }
      this.loading_reporte = false;
    },

    loadChoferes() {
      choferesService
        .getAll()
        .then((response) => {
          this.drivers = response.drivers;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTransport() {
      transportService
        .getAll()
        .then((response) => {
          this.transporte = response.transports;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadSubclients() {
      this.loading_subclientes = true;
      subClientService
        .filter(this.search == "" ? " " : this.search, 0, 100, 1)
        .then((response) => {
          this.subclients = response.list;
          this.loading_subclientes = false;
        })
        .catch((e) => {
          console.log("service.load error");
          this.loading_subclientes = false;

          console.log(e);
        });
    },
    subcli: (item) => (item.id == 0 ? "" : item.nameSubClient + " (" + item.subClientAddress + ")"),
    loadOrigenes() {
      originService
        .getAll()
        .then((response) => {
          this.origines = response.origins;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    limpiarComboBox() {
      this.searchInput = "";
    },
    validateDate(filtro) {
      var flag = true;
      const date1 = new Date(this.dateInicio_filter);
      const date2 = new Date(this.dateFin_filter);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (filtro == "Total") {
        if (diffDays > 7) {
          flag = false;
          this.$toast.error("Debe ingresar un periodo de busqueda menor a 7 dias", "Error");
          return flag;
        }
      } else {
        if (diffDays > 31) {
          flag = false;
          this.$toast.error("Debe ingresar un periodo de busqueda menor a 31 dias", "Error");
          return flag;
        }
      }
      return flag;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    addDaysToDate(date, days) {
      var res = new Date(date);
      res.setDate(res.getDate() + days);
      return this.formatDate(res.toISOString().substr(0, 10));
    },
    validateCampos() {
      if (this.rtaTipo == "" || (this.rtaTipo != "Total" && this.filtro == "")) {        
        this.$toast.error("Por favor complete los campos antes de continuar", "Error");
      } else {
        if (this.rtaTipo == "Total") {
          this.param.type = 1;
          this.param.numType = 0;
        } else if (this.rtaTipo == "Chofer") {
          this.param.type = 2;
          this.param.numType = this.filtro.id;
        } else if (this.rtaTipo == "Transporte") {
          this.param.type = 3;
          this.param.numType = this.filtro.id;
        } else if (this.rtaTipo == "Cliente") {
          this.param.type = 4;
          this.param.numType = this.filtro.id;
        } else if (this.rtaTipo == "Subcliente") {
          this.param.type = 5;
          this.param.numType = this.filtro.id;
        } else if (this.rtaTipo == "Origen") {
          this.param.type = 6;
          this.param.numType = this.filtro.id;
        } else if (this.rtaTipo == "Remito") {
          this.param.type = 7;
          this.param.numType = this.filtro.id;
        }

        if (this.validateDate(this.rtaTipo)) {
          this.getDiaxDia();
        }
      }
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
