<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
            <v-text-field data-vv-as="nombre" :label="$t('Nombre') + '*'" name="nameCompany" v-model="item.nameCompany" v-validate="'required'"></v-text-field>
            <span class="red--text">{{ errors.first("nameCompany") }}</span>

            <v-text-field data-vv-as="costo" :label="$t('Custo') + '*'" name="cost" v-model="item.cost" v-validate="'required|decimal'"></v-text-field>
            <span class="red--text">{{ errors.first("cost") }}</span>

            <v-checkbox :label="$t('Activo')" v-model="item.isActive"></v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {};
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {},
  methods: {
    createItem() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
