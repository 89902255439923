var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{staticStyle:{"width":"100%","height":"600px"},attrs:{"center":_vm.getCenter(),"zoom":9,"options":{
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
    disableDefaultUi: true,
  },"map-type-id":"terrain"}},_vm._l((_vm.drivers),function(driver){return _c('div',{key:driver.numRuta},[_vm._v(" > "),(driver.geo.latitud != null && driver.geo.longitud != null)?_c('GmapMarker',{attrs:{"position":_vm.getPosition(driver.geo.latitud, driver.geo.longitud),"icon":_vm.getIcon(driver),"clickable":true,"draggable":false},on:{"click":function($event){driver.open = true}}},[_c('GmapInfoWindow',{attrs:{"opened":driver.open},on:{"closeclick":function($event){driver.open = false}}},[_c('div',{staticStyle:{"padding":"5px"}},[_c('p',{staticClass:"fs-15 mb-0"},[_c('b',[_vm._v(_vm._s(driver.nombreChofer))])]),_c('p',{staticClass:"fs-15"},[_vm._v(_vm._s(driver.patente))]),_c('p',{staticClass:"fs-15"},[_vm._v("Ruta: #"+_vm._s(driver.numRuta))]),_c('p',{staticClass:"fs-15"},[_vm._v("Ultima actualizacion: "+_vm._s(_vm.transformDate(driver.geo.lastUpdatePosition)))])])])],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }