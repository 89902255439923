<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)"
      v-on:input="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name
          }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-combobox v-model="user_type_selected" data-vv-as="Tipo de usuario" name="user_type_selected"
              item-text="name" item-value="id" :items="user_types" label="Tipo de Usuario*" autocomplete="off"
              v-validate="'required'" @change="handleTypeSelected()" :disabled="isViewModal() || item.id>0">
            </v-combobox>
            <span class="red--text">{{ errors.first("user_type_selected") }}</span>

            <v-combobox v-if="user_type_selected != null && user_type_selected.id == 2" v-model="item.companyExternal"
              data-vv-as="cliente" name="cliente" item-text="nameClient" item-value="id" :items="clients"
              label="Cliente*" autocomplete="off" v-validate="'required'" key="cliente" 
              :disabled="isViewModal()|| item.id>0"></v-combobox>
            <span class="red--text">{{ errors.first("cliente") }}</span>


            <v-combobox v-if="user_type_selected != null && user_type_selected.id == 3" v-model="item.companyExternalTransport"
              data-vv-as="transporte" name="type" item-text="nameTransport" item-value="id" :items="transports"
              label="Transporte" autocomplete="off" v-validate="'required'" :disabled="isViewModal() || item.id>0"></v-combobox>
            <span class="red--text">{{ errors.first("type") }}</span>

            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="nombre" :label="$t('Nombre') + '*'" name="name" v-model="item.name"
                  v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>
                <v-text-field data-vv-as="username" label="Username*" name="username" v-model="item.username"
                  v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("username") }}</span>
                <v-text-field :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show"
                  :type="show ? 'text' : 'password'" label="Password*" data-vv-as="password" name="password"
                  v-validate="'required'" v-model="item.password" :disabled="isViewModal()" />
                <span class="red--text">{{ errors.first("password") }}</span>
                <v-text-field data-vv-as="email" label="Email*" name="userEmail" v-model="item.email"
                  v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("userEmail") }}</span>
                <v-text-field data-vv-as="mobile" :label="$t('Telefono')" name="mobile" v-model="item.mobileNumber"
                  :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("mobile") }}</span>

                <v-combobox v-model="item.rol" data-vv-as="rol" name="rol" item-text="name" item-value="id"
                  :items="roles" label="Rol*" autocomplete="off" v-validate="'required'"
                  :disabled="isViewModal()"></v-combobox>
                <span class="red--text">{{ errors.first("rol") }}</span>

                <div class="row">
                  <div class="col-md-12">
                    <v-checkbox :label="$t('Activo')" :disabled="isViewModal()" v-model="item.isActive"></v-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { usersService } from "@/libs/ws/usersService";
import { clientService } from "@/libs/ws/clientService";
import { transportService } from "@/libs/ws/transportService";
import { rolService } from "@/libs/ws/rolService";
import Constant from "@/constants/";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      roles: [],
      clients: [],
      transports: [],
      user_types: [
        { id: 1, name: "Interno" },
        { id: 2, name: "Cliente (Externo)" },
        { id: 3, name: "Transporte (Externo)" },
      ],
      user_type_selected: null,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog(newVal) {
      this.errors.clear();

      if(newVal) {
        if(this.item.id > 0) {
          this.loadTypeSelected(this.item);
        }
      } else {
         this.user_type_selected = null;
      }
    },
  },
  mounted() {
    this.loadRoles();
    this.loadClients();
    this.loadTransport();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadTypeSelected(newVal) {
      if (newVal.typeUser == Constant.USER_TYPE_INTERNAL) {
        this.user_type_selected = { id: 1, name: "Interno" };
      } else if (newVal.typeUser == Constant.USER_TYPE_EXTERNAL_CLIENT) {
        this.user_type_selected = { id: 2, name: "Cliente (Externo)" };
      } else if (newVal.typeUser == Constant.USER_TYPE_EXTERNAL_PROVEEDOR) {
        this.user_type_selected = { id: 3, name: "Transporte (Externo)" };
      } else if (newVal.typeUser == null) {
        this.user_type_selected = null;
      }
    },
    handleTypeSelected() {
      if(this.user_type_selected.id == 1) {
        this.item.companyExternal = null
        this.item.companyExternalTransport = null
      } 

      if(this.user_type_selected.id == 2) {
        this.item.companyExternalTransport = null
      }

      if(this.user_type_selected.id == 3) {
        this.item.companyExternal = null
      }
    },
    loadRoles() {
      rolService
        .getAll()
        .then((response) => {
          this.roles = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
          if (this.user_type_selected !== null && this.user_type_selected.id == 2) {
            this.item.isExternal = true;
          }
          if (this.item.id > 0 && this.user_type_selected != null) {
            this.service
              .update(this.item, this.user_type_selected)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  this.$toast.error(response.title, "Error");
                }
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            usersService
              .create(this.item, this.user_type_selected)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },

    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTransport() {
      transportService
        .getAll()
        .then((response) => {
          this.transports = response.transports;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
  },
};
</script>
