import { requestService } from "./requestService";

const auth_api_url = process.env.VUE_APP_AUTH_API_URL;
const timeout = 30000; //ms

function login(username, password) {
  const param = {
    Username: username,
    Password: password,
  };
  return requestService.make_post_request(auth_api_url, timeout, "login/loginweb", param);
}

function isAuthenticated() {
  return (
    localStorage.getItem("token") != undefined &&
    localStorage.getItem("expires") != undefined &&
    localStorage.getItem("username") != undefined &&
    Date.now() < Date.parse(localStorage.getItem("expires"))
  );
}

function logOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("expires");
  localStorage.removeItem("username");
  localStorage.removeItem("rol");
  localStorage.removeItem("external");
}

export const authService = {
  login,
  isAuthenticated,
  logOut,
};
