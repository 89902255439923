<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
            <v-text-field data-vv-as="nombre" :label="$t('Nombre') + '*'" name="origen" v-model="item.origen" v-validate="'required'"></v-text-field>
            <span class="red--text">{{ errors.first("origen") }}</span>

            <v-combobox
              v-model="item.country"
              data-vv-as="pais"
              name="pais"
              item-text="name"
              item-value="id"
              :items="countries"
              label="Pais*"
              autocomplete="off"
              v-validate="'required'"
              :disabled="isViewModal()"
              @change="loadProvincies(item.country.id)"
            ></v-combobox>
            <span class="red--text">{{ errors.first("pais") }}</span>

            <v-combobox
              v-model="item.province"
              data-vv-as="provincia"
              name="provincia"
              item-text="name"
              item-value="id"
              :items="provinces"
              label="Provincia*"
              autocomplete="off"
              v-validate="'required'"
              :disabled="isViewModal()"
              @change="loadLocalities(item.province.id)"
            ></v-combobox>
            <span class="red--text">{{ errors.first("provincia") }}</span>

            <v-combobox
              v-model="item.locality"
              data-vv-as="localidad"
              name="localidad"
              item-text="name"
              item-value="id"
              :items="localities"
              label="Localidad*"
              autocomplete="off"
              v-validate="'required'"
              :disabled="isViewModal()"
            ></v-combobox>
            <span class="red--text">{{ errors.first("localidad") }}</span>

            <v-text-field data-vv-as="direccion" :label="$t('Direccion')" name="direccion" v-model="item.originAddress" v-validate="'required'" :disabled="isViewModal()"> </v-text-field>
            <span class="red--text">{{ errors.first("direccion") }}</span>

            <v-checkbox :label="$t('Activo')" v-model="item.isActive"></v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { originService } from "@/libs/ws/originService";
import { countryService } from "@/libs/ws/countryService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      countries: [],
      provinces: [],
      localities: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadCountries();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadCountries() {
      countryService
        .getAll()
        .then((response) => {
          this.countries = response.countries;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadProvincies(country) {
      countryService
        .getAllProvince(country)
        .then((response) => {
          if (this.item.id == "") {
            this.item.province = "";
            this.item.locality = "";
          }
          this.provinces = response.province;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadLocalities(province) {
      countryService
        .getAllLocality(province)
        .then((response) => {
          if (this.item.id == "") {
            this.item.locality = "";
          }
          this.localities = response.locality;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
