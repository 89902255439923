<template>
  <v-dialog v-model="dialog" @click:outside="$emit('close')" max-width="650">
    <v-card>
      <v-card-title class="headline">Historial de Estados</v-card-title>

      <v-card-text>
        <template>
          <v-data-table :loading="loading" :headers="headers" :items="item" :loading-text="$t('cargando_espere')" class="background-color: grey  lighten-3 w-100 mt-4">
            <v-spacer></v-spacer>
            <template v-slot:item="props">
              <tr>
                <td>
                  <p v-if="props.item.fecha">{{ new Date(props.item.fecha).toLocaleDateString() + " " + props.item.fecha.substr(11, 8) }}</p>
                </td>
                <td v-html="getState(props.item.estado.id)"></td>
                <td>#{{ props.item.numTrip }}</td>
                <td>{{ props.item.nameUser }}</td>
                <td>{{ props.item.typeUser }}</td>
              </tr>
            </template>
            <v-spacer></v-spacer>
          </v-data-table>
        </template>
      </v-card-text>
      <v-card-actions>
        <template>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="$emit('close')">
            Cerrar
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { remitosService } from "@/libs/ws/remitosService";

export default {
  props: {
    dialog: Boolean,
    remito: Object,
    close: Function,
    headers: Array,
    updateDialog: Function,
  },
  data() {
    return {
      serviceRemitos: remitosService,
      item: [],
      loading: false,
    };
  },

  watch: {
    dialog() {
      if (this.dialog) this.getHistory();
    },
  },
  mounted() {},
  methods: {
    getHistory() {
      if (this.remito != null) {
        this.loading = true;
        this.serviceRemitos
          .getHistory(this.remito.id)
          .then((response) => {
            this.item = response;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getState(estado) {
      return remitosService.getStatesHTML(estado);
    },
  },
};
</script>
