<template>
  <div>
    <Title
      :pages="[{ icon: 'user-cog', page: page_title.toUpperCase() }]"
    ></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadRoles()"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('Buscar')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          :loading-text="$t('cargando_espere')"
          :pagination.sync="pagination"
        >
          <template v-slot:item="props">
            <tr>
              <td>
                {{ props.item.id }}
              </td>
              <td>{{ props.item.name }}</td>

              <td class="justify-content-center layout px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="update_access"
                      v-on="on"
                      small
                      color="blue"
                      class="mr-2"
                      @click="editItem(props.item)"
                    >
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small
                      color="blue"
                      class="mr-1"
                      @click="viewItem(props.item)"
                    >
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>{{ $t("Ver") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small
                      color="red"
                      class="mr-1"
                      @click="deleteItem(props.item)"
                    >
                      delete
                    </v-icon>
                  </template>
                  <span>{{ $t("Eliminar") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ $t("sin_resultados") + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete
        :dialog_delete="dialog_delete"
        @updateDialogDelete="updateDialogDelete"
        @deleteItemConfirm="deleteItemConfirm"
      />
    </v-container>
  </div>
</template>

<script>
import { rolService } from "@/libs/ws/rolService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  components: { Title, Modal, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      parent: this,
      service: rolService,
      page_title: "Roles",
      modal_title: "Rol",
      roles: [],
      mainItem: {
        Name: "",
        modulos: [],
      },
      defaultItem: null,
      headers: [
        { text: this.$t("Numero"), value: "id" },
        {
          text: this.$t("Nombre"),
          align: "left",
          sortable: true,
          value: "name",
        },
        { text: this.$t("Accion"), value: "enable", align: "center" },
      ],
      list: [],
      dialog_delete: false,
      pagination: {
        rowsPerPage: 30,
      },
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.loadRoles();
    //this.loadList(this.service);
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      setTimeout(() => {
        this.loadRoles();
      }, 1000);
    },
    loadRoles() {
      rolService
        .getAll()
        .then((response) => {
          this.list = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
