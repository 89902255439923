import { genericService } from "./genericService";

const section = "Rol";

function remove(id) {
  return genericService.remove(section, id);
}

function getAll() {
  return genericService.get("Rol/ListAllRol");
}

function create(item) {
  const param = {
    Name: item.name,
    Modulos: item.modulos,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    Name: item.name,
    Modulos: item.modulos,
  };
  return genericService.update(section, param);
}

export const rolService = {
  getAll,
  remove,
  create,
  update,
};
