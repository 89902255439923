<template>
  <div>
    <Title :pages="[{ icon: 'calendar-alt', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadListCustom(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
        @eventUpdated="updateEvent"
      />
      <v-row>
        <v-col class="pl-4 pr-4">
          <v-sheet height="90">
            <v-col>
              <v-toolbar flat>
                <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                  Hoy
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar" style=" text-transform: capitalize">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>

                <div class="col-md-3 mt-2 ml-4">
                  <v-combobox
                    dense
                    v-model="cliente_filter"
                    name="client"
                    item-text="nameClient"
                    item-value="id"
                    :items="clients"
                    :label="$t('Cliente')"
                    autocomplete="off"
                    :clearable="true"
                    @change="loadListCustom(service)"
                  ></v-combobox>
                </div>
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Dia</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mes</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-col>
          </v-sheet>
          <v-sheet height="70vh">
            <v-calendar
              ref="calendar"
              @mousedown:event="handleMouseDown"
              @click:event="showEvent"
              v-model="focus"
              color="primary"
              :events="events"
              :type="type"
              @change="updateRange"
              :now="today"
              :value="today"
            ></v-calendar>
            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark class="pl-4 pr-4">
                  <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="editImages(selectedEvent.item)">
                    <v-icon>image</v-icon>
                  </v-btn>
                  <v-btn icon @click="editItem(selectedEvent.item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="deleteItem(selectedEvent.item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <p>{{ toStringDate(selectedEvent.start) }}</p>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="red" @click="selectedOpen = false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
      <ModalImages button_name="Imagenes" modal_title="Imagenes" :item="mainItem" :dialog="dialog_images" @updateDialog="updateDialogImages" @reload="loadListCustom" />
      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteConfirm" />
    </v-container>
  </div>
</template>

<script>
import { agendaService } from "@/libs/ws/agendaService";
import { clientService } from "@/libs/ws/clientService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Title from "@/components/Title.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ModalImages from "./ModalImages.vue";

export default {
  components: { Title, Modal, DialogDelete, ModalImages },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      parent: this,
      service: agendaService,
      dialog_images: false,
      page_title: "Agenda",
      modal_title: "Evento",
      mainItem: {
        time: "",
        fecha: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        cliente: null,
        observaciones: "",
        images: [],
        usersComunication: [],
      },
      defaultItem: null,
      list: [],
      clients: [],
      dateInicio_filter: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      dateFin_filter: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      cliente_filter: null,
      today: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      focus: "",
      type: "week",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Dia",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      clickedEvent: null,
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.$refs.calendar.scrollToTime("07:00");
    this.loadListCustom(this.service);
    this.loadClients();
  },
  methods: {
    closeSelected() {
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.selectedElement = null;
    },
    editImages(item) {
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);

      this.dialog_images = true;
    },
    updateDialogImages() {
      this.dialog_images = false;
    },

    deleteConfirm() {
      this.delete(this.service);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    loadListCustom(service, startIndex = 0) {
      this.loading = true;
      var params = {
        fecha_desde: this.formatDate(this.dateInicio_filter),
        fecha_hasta: this.formatDate(this.dateFin_filter),
        client_id: this.cliente_filter != null ? this.cliente_filter.id : 0,
      };
      service
        .filter(params, startIndex)
        .then((response) => {
          if (response.list != null) {
            this.list = response.list;
          } else {
            this.list = [];
          }

          this.loadEvents();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    toStringDate(fecha) {
      if (fecha != undefined) {
        // Obtenemos los componentes de la fecha y la hora
        var dia = fecha.getDate();
        var mes = fecha.getMonth() + 1; // ¡Recuerda que los meses son indexados desde 0!
        var anio = fecha.getFullYear();
        var horas = fecha.getHours();
        var minutos = fecha.getMinutes();

        // Formateamos la fecha y la hora según el formato deseado
        var fechaFormateada = `${dia < 10 ? "0" + dia : dia}-${mes < 10 ? "0" + mes : mes}-${anio} ${horas < 10 ? "0" + horas : horas}:${minutos < 10 ? "0" + minutos : minutos}`;
        return fechaFormateada + "hs";
      }
      return null;
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    handleMouseDown(event) {
      //se agrego esto para poder setear selectedEvent ya que si hacias dos click seguidos en el mismo evento no ingresaba al showEvent
      this.selectedEvent = Object.assign({}, event.event);
    },
    showEvent({ nativeEvent, event }) {
      console.log(event, "ENTRE");
      const open = () => {
        console.log(event, "cargue evento");
        this.selectedEvent = Object.assign({}, event);
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      // Fuerza la renderización nuevamente
      this.clickedEvent = null;
      this.$nextTick(() => {
        this.clickedEvent = event;
        console.log("Event clicked:", event);
      });

      nativeEvent.stopPropagation();
    },
    loadEvents() {
      const events = [];

      this.list.forEach((element) => {
        events.push({
          item: element,
          name: element.cliente.nameClient,
          details: element.observaciones,
          start: new Date(element.fecha),
          end: new Date(element.fecha),
          color: "blue",
          timed: true,
        });
      });

      this.events = events;
    },
    updateEvent(updatedEvent) {
      const eventIndex = this.events.findIndex((event) => event.id === updatedEvent.id);
      if (eventIndex !== -1) {
        this.$set(this.events, eventIndex, updatedEvent);
      }
    },
    updateRange({ start, end }) {
      this.dateInicio_filter = start.date;
      this.dateFin_filter = end.date;
      this.loadListCustom(this.service);
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
