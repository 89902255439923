import { akeronService } from "./akeronService";

const section = "/Report/ListReport";

function getReport(item) {
  const param = {
    type: item.type,
    numType: item.numType,
    fromDate: item.fecha_inicio,
    ToDate: item.fecha_fin,
  };
  return akeronService.make_post_request(section, param);
}

export const reportService = {
  getReport,
};
