<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->

            <div class="row">
              <div class="col-md-6">
                <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>

                <v-text-field data-vv-as="nombre" :label="$t('Nombre')" name="nameClient" v-model="item.nameClient" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("nameClient") }}</span>

                <v-text-field data-vv-as="cuit" label="Cuit*" name="cuit" v-model="item.cuit" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("cuit") }}</span>

                <v-text-field data-vv-as="telefono" :label="$t('Telefono')" name="telefono" v-model="item.clientTelephone" :disabled="isViewModal()"> </v-text-field>
                <span class="red--text">{{ errors.first("telefono") }}</span>

                <v-text-field data-vv-as="email" label="Email*" name="email" v-model="item.clientEmail" :disabled="isViewModal()"> </v-text-field>
                <span class="red--text">{{ errors.first("email") }}</span>

                <v-text-field data-vv-as="contacto" label="Contacto*" name="contacto" v-model="item.contact" :disabled="isViewModal()"> </v-text-field>
                <span class="red--text">{{ errors.first("contacto") }}</span>

                <v-checkbox :label="$t('Activo')" v-model="item.isActive"></v-checkbox>
              </div>
              <div class="col-md-6">
                <v-text-field data-vv-as="direccion" :label="$t('Direccion')" name="direccion" v-model="item.clientAddress" v-validate="'required'" :disabled="isViewModal()"> </v-text-field>
                <span class="red--text">{{ errors.first("direccion") }}</span>

                <v-combobox
                  v-model="item.saleRate"
                  data-vv-as="Tarifa de venta"
                  name="saleRate"
                  item-text="name"
                  item-value="id"
                  :items="saleRates"
                  label="Tarifa de venta"
                  autocomplete="off"
                  v-validate="''"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("saleRate") }}</span>

                <v-combobox
                  v-model="item.paymentType"
                  data-vv-as="Tipo de pago"
                  name="payment"
                  item-text="type"
                  item-value="id"
                  :items="payments"
                  label="Tipo de pago*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("payment") }}</span>

                <v-text-field data-vv-as="Precio unitario" label="Precio unitario*" name="precio" v-model="item.priceUnit" :disabled="isViewModal()" v-validate="'required|decimal'"> </v-text-field>
                <span class="red--text">{{ errors.first("precios") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn v-if="!isViewModal()" color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { typePaymentService } from "@/libs/ws/typePaymentService";
import { rateSaleService } from "@/libs/ws/rateSaleService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      payments: [],
      saleRates: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadPayments();
    this.loadSaleRates();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadPayments() {
      typePaymentService
        .getAll()
        .then((response) => {
          this.payments = response.types;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadSaleRates() {
      rateSaleService
        .getAll()
        .then((response) => {
          this.saleRates = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
