<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="submitForm">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
            <div class="row">
              <div class="col-md-12">
                <v-stepper v-model="actualStep" elevation-0>
                  <v-stepper-header class="headerstep">
                    <v-stepper-step :complete="actualStep > 1" step="1">
                      Recoleccion
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="actualStep > 2" step="2">
                      Entrega
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="actualStep > 3" step="3">
                      Adicionales
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="4">
                      Observaciones
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1" class="height-modal-stepper">
                      <div class="row">
                        <div class="col-md-3">
                          <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.datePickup"
                                :label="$t('Fecha_inicio')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="isViewModal()"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.datePickup" min="1950-01-01"></v-date-picker>
                          </v-menu>
                        </div>
                        <div class="col-md-3">
                          <v-menu
                            ref="menu"
                            v-model="menu_time"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="item.time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.time"
                                label="Hora de inicio"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-validate="'required'"
                                v-bind="attrs"
                                name="time"
                                v-on="on"
                                :disabled="isViewModal()"
                              ></v-text-field>
                              <span class="red--text">{{ errors.first("time") }}</span>
                            </template>
                            <v-time-picker v-if="menu_time" v-model="item.time" full-width @click:minute="$refs.menu.save(item.time)"></v-time-picker>
                          </v-menu>
                        </div>
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.originPort"
                            data-vv-as="Recolección"
                            name="recoleccion"
                            item-text="dirOrigen"
                            item-value="id"
                            :items="origin"
                            label="Lugar de recolección"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("recoleccion") }}</span>
                        </div>
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.warehouse"
                            data-vv-as="Desconsolidacion"
                            name="desconsolidacion"
                            item-text="nameWarehouse"
                            item-value="id"
                            :items="warehouses"
                            label="Lugar de desconsolidación"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("desconsolidacion") }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.client"
                            data-vv-as="Cliente"
                            name="cliente"
                            item-text="nameClient"
                            item-value="id"
                            :items="clients"
                            :label="$t('Clientes')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("cliente") }}</span>
                        </div>
                        <div class="col-md-3">
                          <v-text-field v-model="item.numContainer" data-vv-as="Número container" name="NumContainer" label="Numero container" v-validate="'required'"></v-text-field>
                          <span class="red--text">{{ errors.first("NumContainer") }}</span>
                        </div>
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.driverPickup"
                            data-vv-as="Chofer"
                            name="chofer"
                            item-text="nameDriver"
                            item-value="id"
                            :items="drivers"
                            :label="$t('Chofer')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="setVehiculo()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("chofer") }}</span>
                        </div>
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.vehiclePickup"
                            data-vv-as="Vehiculos"
                            name="vehiculo"
                            item-text="patent"
                            item-value="id"
                            :items="vehicles"
                            :label="$t('Vehiculo')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="
                              updateBasicCost();
                              fillComboBoxAdicionales();
                            "
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("vehiculo") }}</span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <v-text-field :value="item.vehiclePickup.companyTransport.nameTransport" :label="$t('Nombre') + ' transporte'" outlined disabled></v-text-field>
                        </div>
                        <div class="col-md-4">
                          <v-text-field :value="item.vehiclePickup.type.type" label="Tipo" outlined disabled></v-text-field>
                        </div>
                        <div class="col-md-4">
                          <v-text-field :value="item.vehiclePickup.maxCharge" label="Carga Máxima" outlined disabled></v-text-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.typeChargePickup"
                            data-vv-as="tipo de carga"
                            name="tipoCarga"
                            item-text="typeCharge"
                            item-value="id"
                            :items="cargoTypes"
                            label="Tipo de Carga"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="updateBasicCost(), fillComboBoxAdicionales()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("tipoCarga") }}</span>
                        </div>

                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.zonePickup"
                            data-vv-as="Zona"
                            name="zona"
                            item-text="zone"
                            item-value="id"
                            :items="zones"
                            label="Zona"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="updateBasicCost(), fillComboBoxAdicionales()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("zona") }}</span>
                        </div>
                        <div class="col-md-3">
                          <v-combobox
                            v-model="item.typeTripPickup"
                            :data-vv-as="$t('Tipo_contratacion')"
                            name="tipoViaje"
                            item-text="typeTrip"
                            item-value="id"
                            :items="typeTrips"
                            :label="$t('Tipo_contratacion')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                            @change="updateBasicCost(), fillComboBoxAdicionales()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("tipoViaje") }}</span>
                        </div>
                        <div class="col-md-3">
                          <v-text-field v-model="item.externalCode" label="Id. Externo" outlined></v-text-field>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-end">
                        <div class="col-md-4 ">
                          <v-text-field v-model="item.basicCost" label="Costo Básico" outlined disabled></v-text-field>
                        </div>
                      </div>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="height-modal-stepper">
                      <div class="row">
                        <div class="col-md-3">
                          <v-menu ref="menu2" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.dateDelivery"
                                :label="$t('Fecha_inicio')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="isViewModal()"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.dateDelivery" min="1950-01-01"></v-date-picker>
                          </v-menu>
                        </div>
                        <div class="col-md-3">
                          <v-menu
                            ref="menu2"
                            v-model="menu_time_delivery"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="item.time_delivery"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="item.time_delivery"
                                label="Hora de inicio"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-validate="'required'"
                                v-bind="attrs"
                                name="time"
                                v-on="on"
                                :disabled="isViewModal()"
                              ></v-text-field>
                              <span class="red--text">{{ errors.first("time") }}</span>
                            </template>
                            <v-time-picker v-if="menu_time_delivery" v-model="item.time_delivery" full-width @click:minute="$refs.menu2.save(item.time_delivery)"></v-time-picker>
                          </v-menu>
                        </div>
                        <div class="col-md-6">
                          <v-combobox
                            v-model="item.destinationPort"
                            data-vv-as="entrega"
                            name="entrega"
                            item-text="dirOrigen"
                            item-value="id"
                            :items="origin"
                            label="Entrega"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("entrega") }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <v-combobox
                            v-model="item.driverDelivery"
                            data-vv-as="Chofer"
                            name="chofer"
                            item-text="nameDriver"
                            item-value="id"
                            :items="drivers"
                            :label="$t('Chofer')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("chofer") }}</span>
                        </div>
                        <div class="col-md-6">
                          <v-combobox
                            v-model="item.vehicleDelivery"
                            data-vv-as="Vehiculos"
                            name="vehiculo"
                            item-text="patent"
                            item-value="id"
                            :items="vehicles"
                            :label="$t('Vehiculo')"
                            autocomplete="off"
                            v-validate="'required'"
                            :disabled="isViewModal()"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("vehiculo") }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <v-text-field :value="item.vehicleDelivery.companyTransport.nameTransport" :label="$t('Nombre') + ' transporte'" outlined disabled></v-text-field>
                        </div>
                        <div class="col-md-4">
                          <v-text-field :value="item.vehicleDelivery.type.type" label="Tipo" outlined disabled></v-text-field>
                        </div>
                        <div class="col-md-4">
                          <v-text-field :value="item.vehicleDelivery.maxCharge" label="Carga Máxima" outlined disabled></v-text-field>
                        </div>
                      </div>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="height-modal-stepper">
                      <div class="row mt-1">
                        <div class="col-md-9">
                          <v-combobox
                            v-model="adicional_selected"
                            data-vv-as="Adicional"
                            name="tipo"
                            :items="combobox_adicionales"
                            item-text="label"
                            item-value="tipo"
                            label="Adicionales"
                            autocomplete="off"
                            :disabled="isViewModal()"
                          ></v-combobox>
                        </div>
                        <div class="col-md-2">
                          <v-btn small color="primary" class="mt-5 white--text" @click="addAdicional(adicional_selected)" :disabled="isViewModal()">
                            <v-icon left dark>
                              add
                            </v-icon>
                            Agregar
                          </v-btn>
                        </div>
                      </div>
                      <div class="row mt-0">
                        <div class="col-md-12">
                          <div v-for="(adicional, index) of item.aditionals" :key="adicional.name">
                            <Adicional :adicional="adicional" @delete_adicional="delete_adicional" :index="index" @setQuantity="setQuantity" :isViewModal="isViewModal()"></Adicional>
                          </div>
                        </div>
                      </div>
                    </v-stepper-content>
                  </v-stepper-items>

                  <v-stepper-content step="4" class="height-modal-stepper">
                    <div class="row">
                      <div class="col-md-12">
                        <v-combobox
                          v-model="item.companyCustody"
                          data-vv-as="Compañia de Seguros"
                          name="companyCustody"
                          item-text="nameCompany"
                          item-value="id"
                          :value="null"
                          :items="securityCompanies"
                          :label="$t('Compania_seguros')"
                          autocomplete="off"
                          :disabled="isViewModal()"
                          @change="calculateCustodyCost()"
                        ></v-combobox>
                        <span class="red--text">{{ errors.first("companyCustody") }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <v-combobox
                          v-model="item.quantityModules"
                          data-vv-as="Modulos"
                          name="quantityModules"
                          :items="cmbFiller"
                          label="Modulos"
                          autocomplete="off"
                          v-validate="'required'"
                          :disabled="isViewModal() || item.companyCustody == null"
                          @change="calculateCustodyCost()"
                        ></v-combobox>
                        <span class="red--text">{{ errors.first("quantityModules") }}</span>
                      </div>
                      <div class="col-md-4">
                        <v-text-field label="Coste Modulo" :value="item.companyCustody != null ? item.companyCustody.cost : 0" outlined disabled></v-text-field>
                      </div>
                      <div class="col-md-4">
                        <v-text-field label="Costo Custodia" v-model="moduleTotalCost" outlined disabled></v-text-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <v-text-field v-model="item.observation1" :label="$t('Observaciones') + ' 1'" :disabled="isViewModal()"></v-text-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <v-text-field v-model="item.observation2" :label="$t('Observaciones') + ' 2'" :disabled="isViewModal()"></v-text-field>
                      </div>
                    </div>
                    <div class="row  d-flex justify-content-end">
                      <div class="col-md-4">
                        <v-text-field label="Costo Total" :value="totalCost" outlined disabled></v-text-field>
                      </div>
                    </div>
                  </v-stepper-content>
                </v-stepper>

                <div class="float-left">
                  <v-btn @click="backStep()" v-show="actualStep > 1" depressed color="error" class="mt-4 mr-4">
                    Atrás
                  </v-btn>
                </div>
                <div class="float-right">
                  <v-btn color="primary" @click="nextStep()" v-if="actualStep >= 1 && actualStep < 4" class="mt-4">
                    {{ $t("Siguiente") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn v-if="formBtn != ''" color="success" text type="submit" :disabled="actualStep < 4">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { vehiculoService } from "@/libs/ws/vehiculoService";
import { choferesService } from "@/libs/ws/choferesService";
import { typeChargeService } from "@/libs/ws/typeChargeService";
import { zoneService } from "@/libs/ws/zoneService";
import { typeTripService } from "@/libs/ws/typeTripService";
import { originService } from "@/libs/ws/originService";
import { tipoviajeService } from "@/libs/ws/tipoviajeService";
import { securityCompanyService } from "@/libs/ws/securityCompanyService";
import { purchaseRateService } from "@/libs/ws/purchaseRateService";
import { clientService } from "@/libs/ws/clientService";
import Adicional from "./Adicionales.vue";
export default {
  components: {
    Adicional,
  },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      actualStep: 1,
      show: false,
      clients: [],
      drivers: [],
      vehicles: [],
      cargoTypes: [],
      zones: [],
      typeTrips: [],
      origin: [],
      warehouses: [],
      destinations: [],
      securityCompanies: [],
      peonCost: 0,
      stayCost: 0,
      turnsCost: 0,
      delayCost: 0,
      moduleCost: 0,
      moduleTotalCost: 0,
      totalCost: 0,
      purchaseRate: null,
      menu_time: false,
      menu_time_delivery: false,

      cmbFiller: [0, 1, 2, 3, 4, 5],
      cmbFillerEstadias: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      combobox_adicionales: [],
      adicionales: [],
      adicional_selected: null,
      total_cost_adicionales: 0,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();

      if (this.dialog) {
        this.actualStep = 1;

        if (this.item.id != 0) {
          this.item.time = this.item.datePickup.substr(11, 8);
          if (this.item.dateDelivery != null) this.item.time_delivery = this.item.dateDelivery.substr(11, 8);
        }
        var datePickup = this.item.datePickup.split("T");
        var dateDelivery = this.item.dateDelivery.split("T");

        this.item.datePickup = datePickup[0];
        this.item.datePickup =
          this.item.datePickup != null
            ? datePickup[0]
            : new Date(
                new Date().toLocaleDateString("en-US", {
                  timeZone: "America/Buenos_Aires",
                })
              )
                .toISOString()
                .slice(0, 10);
        this.item.dateDelivery =
          this.item.dateDelivery != null
            ? dateDelivery[0]
            : new Date(
                new Date().toLocaleDateString("en-US", {
                  timeZone: "America/Buenos_Aires",
                })
              )
                .toISOString()
                .slice(0, 10);
        this.updateBasicCost();
        this.$emit("updateItem", this.item);
      }
      if (this.dialog && this.item.id == 0) {
        this.item.aditionals = [];
      }
      this.adicional_selected = "";
    },
  },
  mounted() {
    this.loadVehiculos();
    this.loadChoferes();
    this.loadTipoDeCargas();
    this.loadZones();
    this.loadTripTypes();
    this.loadOrigenes();
    this.loadDestinations();
    this.loadSecurityCompanies();
    this.loadClients();
    this.loadWarehouse();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadSecurityCompanies() {
      securityCompanyService
        .filter(" ", 0, 100, 1)
        .then((response) => {
          this.securityCompanies = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadDestinations() {
      tipoviajeService
        .getAll()
        .then((response) => {
          this.destinations = response.destinations;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadWarehouse() {
      this.service
        .getAllWarehouse()
        .then((response) => {
          this.warehouses = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadOrigenes() {
      originService
        .getAll()
        .then((response) => {
          this.origin = response.origins;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTripTypes() {
      typeTripService
        .getAll()
        .then((response) => {
          this.typeTrips = response.types;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadZones() {
      zoneService
        .getAll()
        .then((response) => {
          this.zones = response.zones;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadVehiculos() {
      vehiculoService
        .getAll()
        .then((response) => {
          this.vehicles = response.vehicles;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadChoferes() {
      choferesService
        .getAll()
        .then((response) => {
          this.drivers = response.drivers;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTipoDeCargas() {
      typeChargeService
        .getAll()
        .then((response) => {
          this.cargoTypes = response.types;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      this.item.datePickup = this.formatDate(this.item.datePickup);
      this.item.dateDelivery = this.formatDate(this.item.dateDelivery);

      if (this.item.id > 0) {
        this.service
          .update(this.item)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_actualizo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          });
      } else {
        this.service
          .create(this.item)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_creo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
              this.$emit("id_rutaCreada", response.id);
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          });
      }
    },
    validarStep1() {
      var flag = true;
      if (this.item.time == "") {
        flag = false;
        this.errors.add({
          field: "time",
          msg: "Por favor ingrese la hora de inicio",
        });
      }
      if (this.item.client == "") {
        flag = false;
        this.errors.add({
          field: "cliente",
          msg: "Por favor seleccione un cliente",
        });
      }
      if (this.item.originPort == "") {
        flag = false;
        this.errors.add({
          field: "recoleccion",
          msg: "Por favor ingrese lugar de recoleccion",
        });
      }
      if (this.item.driverPickup == "") {
        flag = false;
        this.errors.add({
          field: "chofer",
          msg: "Por favor ingrese el chofer",
        });
      }

      if (this.item.vehiclePickup.id == "") {
        flag = false;
        this.errors.add({
          field: "vehiculo",
          msg: "Por favor ingrese el vehiculo",
        });
      }

      if (this.item.warehouse == "") {
        flag = false;
        this.errors.add({
          field: "desconsolidacion",
          msg: "Por favor ingrese el lugar de desconsolidacion",
        });
      }

      if (this.item.typeChargePickup == "") {
        flag = false;
        this.errors.add({
          field: "tipoCarga",
          msg: "Por favor ingrese el Tipo de carga",
        });
      }

      if (this.item.zonePickup == "") {
        flag = false;
        this.errors.add({
          field: "zona",
          msg: "Por favor ingrese la zona",
        });
      }
      if (this.item.typeTripPickup == "") {
        flag = false;
        this.errors.add({
          field: "tipoViaje",
          msg: "Por favor ingrese el tipo de contratacion",
        });
      }
      if (this.item.numContainer == "") {
        flag = false;
        this.errors.add({
          field: "NumContainer",
          msg: "Por favor ingrese el n° de container",
        });
      }

      //seteo chofer y vehiculo igual paso 1 para paso 2, solo al momento de crear
      if (flag && this.item.id == 0) {
        this.item.driverDelivery = this.item.driverPickup;
        this.item.vehicleDelivery = this.item.vehiclePickup;
      }

      this.fillComboBoxAdicionales();
      return flag;
    },
    validarStep2() {
      var flag = true;

      if (this.item.destinationPort == "") {
        flag = false;
        this.errors.add({
          field: "entrega",
          msg: "Por favor ingrese lugar de entrega",
        });
      }

      this.calculateTotalCost();
      return flag;
    },

    validarStep3() {
      var flag = true;
      return flag;
    },
    validarStep4() {
      var flag = true;
      if (this.item.quantityModules == null) {
        flag = false;
        this.errors.add({
          field: "quantityModules",
          msg: "Por favor ingrese la cantidad de modulos",
        });
      }
      return flag;
    },
    nextStep() {
      if (this.actualStep != 4) {
        if ((this.actualStep == 1 && this.validarStep1()) || (this.actualStep == 2 && this.validarStep2()) || (this.actualStep == 3 && this.validarStep3())) {
          this.actualStep++;
          this.errors.clear();
        }
      }
    },
    backStep() {
      if (this.actualStep != 1) {
        this.actualStep--;
      }
    },
    submitForm() {
      if (this.validarStep4()) {
        this.createItem();
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    updateBasicCost() {
      if (this.item.typeChargePickup != "" && this.item.zonePickup != "" && this.item.vehiclePickup != "") {
        purchaseRateService
          .getBasicCost(this.item.vehiclePickup.type.id, this.item.typeChargePickup.id, this.item.zonePickup.id)
          .then((response) => {
            this.item.basicCost = response.basicCost;
            this.purchaseRate = response;
            /* this.setCostsStep2(); */
          })
          .catch((e) => {
            console.log("service.load error");
            console.log(e);
          });
      }
    },
    fillComboBoxAdicionales() {
      if (this.item.typeChargePickup != "" && this.item.zonePickup != "" && this.item.vehiclePickup != "") {
        purchaseRateService
          .FindPurchaseRateNew(this.item.vehiclePickup.type.id, this.item.typeChargePickup.id, this.item.zonePickup.id)
          .then((response) => {
            response.forEach((element) => {
              element.label = this.$t(element.tipo);
              element.quantity = 1;
            });
            this.combobox_adicionales = response;
            this.updateAdicionales();
          })
          .catch((e) => {
            console.log("service.load error");
            console.log(e);
          });
      }
    },
    updateAdicionales() {
      if (this.item.aditionals.length > 0) {
        var aux = {};
        var i = 0;
        this.item.aditionals.forEach((element) => {
          var aux_i = -1;
          this.combobox_adicionales.forEach((element1) => {
            if (element.tipo == element1.tipo) {
              aux_i = i;
              aux = {
                tipo: element1.tipo,
                quantity: element.quantity, //porque tengo que guardarme la cantidad que ya tenía antes
                cost: element1.cost,
              };
            }
          });
          if (aux_i >= 0) {
            this.item.aditionals[i] = Object.assign({}, aux);
          }
          i++;
        });
      }
    },

    calculateCustodyCost() {
      if (this.item.companyCustody != null) {
        this.moduleTotalCost = parseFloat(this.item.companyCustody.cost) * parseFloat(this.item.quantityModules);
        this.calculateTotalCost();
      } else {
        this.moduleTotalCost = 0;
        this.item.quantityModules = 0;
      }
    },
    calculateTotalCost() {
      var adicionales_cost = this.calculateAditionalsCost();
      //var peaje = this.item.peajes == "" ? 0 : parseInt(this.item.peajes);
      this.totalCost = adicionales_cost + this.moduleTotalCost + this.item.basicCost;

      this.item.totalCost = this.totalCost;
    },
    calculateAditionalsCost() {
      var costo_total = 0;
      this.item.aditionals.forEach((element) => {
        var costo_unitario = 0;
        costo_unitario = element.quantity * element.cost;
        costo_total += costo_unitario;
      });
      return costo_total;
    },
    setVehiculo() {
      this.item.vehiclePickup = this.item.driverPickup.vehicle;
      this.updateBasicCost();
      this.fillComboBoxAdicionales();
    },
    addAdicional(adicional) {
      const index = this.item.aditionals.map((e) => e.tipo).indexOf(adicional.tipo);
      if (index > -1) {
        this.$toast.error("No se pueden repetir servicios", "Error");
      } else {
        this.item.aditionals.push(adicional);
        this.calculateTotalCost();
      }
      this.adicional_selected = "";
    },
    delete_adicional(adicional) {
      const index = this.item.aditionals.map((e) => e.tipo).indexOf(adicional.tipo);
      if (index > -1) {
        this.item.aditionals.splice(index, 1);
      }
      this.adicional_selected = "";
    },
    setQuantity(index, quantity) {
      this.item.aditionals[index].quantity = quantity;
    },
  },
};
</script>
