<template>
    <v-dialog v-if="item != null" v-model="dialog" @click:outside="$emit('close', item)" max-width="650">
      <v-card>
        <v-card-title class="headline">Subir Imagenes</v-card-title>
  
        <v-card-text>
          <div class="row mt-4 pb-3"></div>
          <p class="mb-0 fs-16">Seleccione las imagenes que desea agregar al viaje</p>
          <v-file-input v-model="item.imagesUpload" counter multiple truncate-length="15"></v-file-input>
  
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
  
          <v-btn color="red darken-1" text @click="$emit('close', item)">
            Cancelar
          </v-btn>
  
          <v-btn color="green darken-1" :loading="loading" text @click="$emit('uploadImages', item)">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      dialog: Boolean,
      loading: Boolean,
      item: Object,
      uploadImages: Function,
      close: Function,
    },
    data() {
      return {};
    },
    watch: {
      dialog() {
        console.log(this.item, "abri");
      },
    },
    mounted() {},
    methods: {},
  };
  </script>
  