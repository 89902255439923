<template>
  <v-dialog v-if="item != null" v-model="dialog" @click:outside="$emit('close', item)" max-width="650">
    <v-card>
      <v-card-title class="headline">Entregar remito</v-card-title>

      <v-card-text>
        <p class="mt-5 mb-5" style="color:black; font-size:18px">
          Estas por entregar el remito <strong>#{{ item.numRemito }}</strong>
        </p>

        <div class="row">
          <div class="col-md-6 pb-0">
            <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="item.date" label="Fecha" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>

              <v-date-picker v-model="item.date" min="1950-01-01"></v-date-picker>
            </v-menu>
          </div>
          <div class="col-md-6">
            <v-combobox v-model="item.state" data-vv-as="Estado" name="Estado" item-text="name" item-value="id" :items="states" label="Estado" autocomplete="off" v-validate="'required'"></v-combobox>
          </div>
        </div>

        <div class="row pt-0 pb-3"></div>

        <p class="mb-0 fs-16">Seleccione las imagenes que desea subir a la entrega</p>

        <v-file-input v-model="item.imagesUpload" counter multiple truncate-length="15"></v-file-input>

        <div class="row mt-3">
          <div class="col-md-12">
            <v-text-field dense v-model="item.observation" :label="$t('Observaciones')"></v-text-field>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="$emit('close', item)">
          Cancelar
        </v-btn>

        <v-btn color="green darken-1" :loading="loading" text @click="validate">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { remitosService } from "@/libs/ws/remitosService";
export default {
  props: {
    dialog: Boolean,
    loading: Boolean,
    item: Object,
    entregarRemito: Function,
    close: Function,
  },
  data() {
    return {
      states: [
        { id: remitosService.ENTREGADO, name: "ENTREGADO" },
        { id: remitosService.NO_ENTREGADO, name: "NO ENTREGADO" },
      ],
    };
  },
  watch: {},
  mounted() {},
  methods: {
    validate() {
      if (this.item.state == null) {
        this.$toast.error("Debe seleccionar un estado", "Error");
        return;
      }

      this.$emit("entregarRemito", this.item);
    },
  },
};
</script>
