<template>
  <div>
    <Title :pages="[{ icon: 'desktop', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-form v-if="list_access">
        <div class="col-md-12 mb-3">
          <div class="row filter" style="margin:1px ; padding:10px">
            <div class="  d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
          </div>
          <div class="row filter py-0" style="margin:1px ">
            <div class="col-md-2">
              <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :disabled="numTrip_filter.length > 0 || numRemito_filter.length > 0"
                    v-model="date_filter"
                    :label="$t('Fecha')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date_filter" min="1950-01-01"></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-2">
              <v-text-field data-vv-as="ruta" :label="$t('Numero_de_ruta')" name="zone" v-model="numTrip_filter"></v-text-field>
            </div>
            <div class="col-md-2">
              <v-text-field data-vv-as="remitos" :label="'Número de ' + $t('Remito')" name="zone" v-model="numRemito_filter"></v-text-field>
            </div>
            <div class="col-md-3">
              <v-combobox v-model="cliente_filter" item-text="nameClient" item-value="id" :items="clients" :label="$t('Cliente')" autocomplete="off" :clearable="true"></v-combobox>
            </div>
            <div class="col-md-2 d-flex align-items-right justify-content-end pt-8">
              <v-btn :color="searching ? 'grey' : 'primary'" dark @click="searching ? null : getMonitoreo()">{{ $t("Buscar") }}</v-btn>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <v-progress-linear v-if="searching" value="15" indeterminate></v-progress-linear>
          <GeoDrivers :drivers="driversList" />
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import GeoDrivers from "@/views/MonitoreoDrivers/GeoDrivers.vue";
import { monitoreoContainerService } from "@/libs/ws/monitoreoContainerService";
import RolMixin from "@/mixins/RolMixin.vue";
import { clientService } from "@/libs/ws/clientService";

export default {
  components: { Title, GeoDrivers },
  mixins: [RolMixin],
  data() {
    return {
      searching: false,
      driversList: [],
      clients: [],
      numRemito_filter: "",
      numTrip_filter: "",
      cliente_filter: null,
      loading_monitoring: true,
      page_title: this.$t("Monitoreo") + " Choferes",
      modal_title: this.$t("Monitoreo"),
      date_filter: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
    };
  },
  mounted() {
    this.getMonitoreo();
    this.updateEvery();
    this.loadClients();
  },
  methods: {
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    updateEvery() {
      this.interval = setInterval(
        function() {
          this.getMonitoreo();
        }.bind(this),
        60000
      );
    },
    getMonitoreo() {
      this.searching = true;
      var params = this.getFilterParams();
      var self = this;
      monitoreoContainerService
        .getMonitoreoDrivers(params)
        .then((response) => {
          if (Array.isArray(response)) {
            self.driversList = response;
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        })
        .finally(() => {
          self.searching = false;
        });
    },
    getFilterParams() {
      var params = {
        clientId: this.cliente_filter != null ? this.cliente_filter.id : 0,
        numRemito: this.numRemito_filter,
        numTrip: this.numTrip_filter == "" ? 0 : parseInt(this.numTrip_filter, 10),
        Fecha: this.formatDate(this.date_filter),
      };
      return params;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
