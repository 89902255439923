<template>
  <div>
    <div class="col-md-12 pt-0">
      <div :class="boxClass()">
        <div class="col-md-1 col-xs-12 subBoxChofer">
          <p class="mb-0">
            <b>#{{ ruta.numTrip }}</b>
          </p>
          <font-awesome-icon icon="chevron-down" class="cursor" style="color:#3483fa" @click="menu = true" v-if="!menu" />
          <font-awesome-icon icon="chevron-up" class="cursor" style="color:#3483fa" @click="menu = false" v-else />
        </div>
        <div class="col-md-2 subBoxChofer" @click="goToDetailRuta()">
          <p class="mb-0">
            <b>Recoleccion</b>
          </p>
          <span style="text-transform: uppercase;">{{ ruta.originPort != null ? ruta.originPort.dirOrigen : "-" }}</span>
        </div>
        <div class="col-md-2 subBoxChofer" @click="goToDetailRuta()">
          <p class="mb-0">
            <b>Desconsolidado</b>
          </p>
          <span style="text-transform: uppercase;">{{ ruta.warehouse != null ? ruta.warehouse.nameWarehouse : "-" }}</span>
        </div>
        <div class="col-md-2 subBoxChofer" @click="goToDetailRuta()">
          <p class="mb-0">
            <b>Entrega</b>
          </p>
          <span style="text-transform: uppercase;">{{ ruta.destinationPort != null ? ruta.destinationPort.dirOrigen : "-" }}</span>
        </div>

        <div class="col-md-1" @click="goToDetailRuta()">
          <div id="chart">
            <apexchart v-if="ruta.stages.length - getDelivery() != 0" ref="Chart01" type="donut" width="60" height="90" :options="chart01Options" :series="series01" />
            <apexchart v-else ref="Chart01" type="donut" width="60" height="90" :options="chart02Options" :series="series01" />
          </div>
        </div>

        <div class="col-md-2 subBoxChofer" @click="goToDetailRuta()">
          <p class="mb-0">
            <b>Contenedor</b>
          </p>
          <span style="text-transform: uppercase;">{{ ruta.numContainer }}</span>
        </div>
        <div class="col-md-2" style="display: flex;align-items: center;justify-content: end;" @click="goToDetailRuta()">
          <b v-if="ruta.stages[0].delivery == null" class="state_en_espera">{{ $t("En_espera") }}</b>
          <b v-else-if="isComplete()" class="state_entregado">Completada</b>
          <b v-else-if="getDelivery() > 0" class="state_en_camino">{{ $t("En_curso") }}</b>
        </div>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="menu" :class="this.isComplete() ? 'extra-info-monitoreo complete' : 'extra-info-monitoreo enCurso'">
        <div class="row pl-3 pr-3">
          <div class="col-md-1 subBoxChofer"></div>
          <div class="col-md-2 subBoxChofer">
            <p style="color:grey;font-size:12px;height:18px">
              RECOLECCION
            </p>
            <p class="mb-0">
              <b>Chofer</b>
            </p>
            <span>{{ ruta.driverPickup != null ? ruta.driverPickup.nameDriver : "-" }}</span>
          </div>
          <div class="col-md-2 subBoxChofer">
            <p style="height:18px"></p>
            <p class="mb-0">
              <b>Vehiculo</b>
            </p>
            <p class="mb-0">{{ ruta.vehiclePickup != null ? ruta.vehiclePickup.patent : "-" }}</p>
            <p class="mb-0">{{ ruta.vehiclePickup != null ? ruta.vehiclePickup.companyTransport.nameTransport : "-" }}</p>
          </div>

          <div class="col-md-2 subBoxChofer">
            <p style="color:grey;font-size:12px;height:18px">
              ENTREGA
            </p>
            <p class="mb-0">
              <b>Chofer</b>
            </p>
            <span>{{ ruta.driverDelivery != null ? ruta.driverDelivery.nameDriver : "-" }}</span>
          </div>
          <div class="col-md-2 subBoxChofer">
            <p style="height:18px"></p>
            <p class="mb-0">
              <b>Vehiculo</b>
            </p>
            <p class="mb-0">{{ ruta.vehicleDelivery != null ? ruta.vehicleDelivery.patent : "-" }}</p>
            <p class="mb-0">{{ ruta.vehicleDelivery != null ? ruta.vehicleDelivery.companyTransport.nameTransport : "-" }}</p>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: "RutaMonitoring",
  props: {
    ruta: { Object, default: null },
  },
  data() {
    return {
      menu: false,
      series01: [],
      chart02Options: {},
      chart01Options: {
        stroke: { show: false },
        colors: ["#f23d4f", "#d4d4d4"],
        legend: { show: false },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: { offsetY: -20 },
                value: {
                  fontSize: 16,
                  offsetY: -10,
                  color: "#f23d4f",
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "",
                  formatter: function(w) {
                    var result = 0;

                    if (parseInt(w.globals.series[0]) + parseInt(w.globals.series[1]) == 0) {
                      result = 0;
                    } else {
                      result = Math.trunc((w.globals.series[0] * 100) / (w.globals.series[0] + w.globals.series[1]));
                    }

                    return result + "%";
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {
    rutaUpdate() {
      return this.ruta;
    },
  },
  watch: {},
  mounted() {
    var aux = Object.assign({}, this.chart01Options);
    aux.colors = ["#d4d4d4", "#d4d4d4"];
    aux.plotOptions.pie.donut.labels.value.color = "#d4d4d4";
    this.chart02Options = Object.assign({}, aux);

    this.series01 = [parseInt(this.getDelivery()), parseInt(this.ruta.stages.length - this.getDelivery())];
  },
  methods: {
    goToDetailRuta() {
      this.$router.push("/containers/monitoring/route/" + this.ruta.id);
    },
    isComplete() {
      if (this.ruta != null && this.getDelivery() == this.ruta.stages.length) {
        return true;
      } else {
        return false;
      }
    },
    boxClass() {
      if (this.isComplete()) {
        return !this.menu ? "box-chofer complete row" : "box-chofer-open complete row";
      } else {
        return !this.menu ? "box-chofer enCurso row" : "box-chofer-open enCurso row";
      }
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },
    getDelivery() {
      var contador = 0;
      this.ruta.stages.forEach(function(elemento) {
        if (elemento.delivery != null) {
          contador++;
        }
      });
      return contador;
    },
  },
};
</script>
