var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"pages":[{ icon: 'clipboard-list', page: _vm.page_title.toUpperCase() }]}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.create_access)?_c('Modal',{attrs:{"button_name":_vm.$t('Crear') + ' ' + _vm.modal_title,"modal_title":_vm.modal_title,"formBtn":_vm.formBtn,"dialog":_vm.dialog,"parent":_vm.parent,"service":_vm.service,"item":_vm.mainItem},on:{"updateDialog":_vm.updateDialog,"loadList":function($event){_vm.loadList(_vm.service, _vm.name_filter, (_vm.current_page - 1) * _vm.$store.state.config.items_per_page)},"updateItem":function (newItem) {
          _vm.mainItem = newItem;
        }}}):_vm._e(),(_vm.list_access)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Filtros")))]),_c('font-awesome-icon',{attrs:{"icon":"filter"}})],1),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('v-text-field',{attrs:{"data-vv-as":"nombre","label":_vm.$t('Nombre') + '*',"name":"zone"},model:{value:(_vm.name_filter),callback:function ($$v) {_vm.name_filter=$$v},expression:"name_filter"}})],1),_c('div',{staticClass:"col-md-4"},[_c('v-checkbox',{attrs:{"label":_vm.$t('Activo')},model:{value:(_vm.active_filter),callback:function ($$v) {_vm.active_filter=$$v},expression:"active_filter"}})],1),_c('div',{staticClass:"col-md-4 d-flex align-items-center justify-content-end pr-10"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.loadList(_vm.service)}}},[_vm._v(_vm._s(_vm.$t("Buscar")))])],1)])])])]):_vm._e(),(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.page_title))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('Buscar'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"mb-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length != 0),expression:"selected.length != 0"}],staticClass:"ml-4",attrs:{"color":"green"},on:{"click":function($event){return _vm.incrementPrice()}}},on),[_vm._v(" add ")])]}}],null,false,100793505)},[_c('span',[_vm._v("Aumentar precios")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('cargando_espere'),"show-select":"","single-select":false},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_c('v-checkbox',{staticStyle:{"margin":"0px","padding":"0px"},attrs:{"input-value":props.isSelected,"hide-details":""},on:{"change":function($event){return props.select($event)}}})],1),_c('td',[_vm._v(" "+_vm._s((_vm.pagination.page - 1) * _vm.pagination.itemsPerPage + props.index + 1)+" ")]),_c('td',[_vm._v(_vm._s(props.item.vehicleType.type))]),_c('td',[_vm._v(_vm._s(props.item.chargeType.typeCharge))]),_c('td',[_vm._v(_vm._s(props.item.zone.zone))]),_c('td',[_vm._v(_vm._s(props.item.basicCost))]),_c('td',{staticClass:"justify-content-center layout px-0"},[(_vm.update_access)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(props.item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Editar")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewItem(props.item)}}},on),[_vm._v(" remove_red_eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Ver")))])])],1)])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("sin_resultados") + _vm.search)+" ")])]},proxy:true}],null,false,1664018620),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),_c('DialogDelete',{attrs:{"dialog_delete":_vm.dialog_delete},on:{"updateDialogDelete":_vm.updateDialogDelete,"deleteItemConfirm":_vm.deleteItemConfirm}}),_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_vm._v(" Incrementar precios ")]),_c('v-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.selected.length == 1)?_c('h6',{staticClass:"mb-0",staticStyle:{"color":"black"}},[_vm._v("Seleccionaste "+_vm._s(_vm.selected.length)+" tarifa")]):_c('h6',{staticClass:"mb-0",staticStyle:{"color":"black"}},[_vm._v("Seleccionaste "+_vm._s(_vm.selected.length)+" tarifas")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-text-field',{attrs:{"label":"Porcentaje a aumentar","suffix":"%"},model:{value:(_vm.porcentage),callback:function ($$v) {_vm.porcentage=$$v},expression:"porcentage"}})],1)])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){return _vm.incrementPriceConfirm()}}},[_vm._v("Confirmar")])],1)],1)]}}]),model:{value:(_vm.dialog_increment),callback:function ($$v) {_vm.dialog_increment=$$v},expression:"dialog_increment"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }