<template>
  <div>
    <Title :pages="[{ icon: 'receipt', page: page_title.toUpperCase() }]"></Title>
    <v-system-bar v-show="selected.length > 0" dark color="primary" style="z-index: 100;position:fixed;bottom:0px;height:55px; width:100%" @click="dialog_editremitos = true" class="cursor">
      <span style="color:white" class=" title">
        <font-awesome-icon icon="receipt" class="ml-2 mr-2" />
        Remitos seleccionados:
        {{ selected.length }}
      </span>
    </v-system-bar>

    <v-container fluid>
      <ModalRemitos
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + $t('Remitos')"
        modal_title="Remitos"
        :formBtn="formBtnRemito"
        :dialog="dialogRemitos"
        :service="serviceRemitos"
        @updateDialog="updateDialogRemitos"
        @loadList="loadPageCustom(service)"
        :item="mainItemRemito"
        @updateItem="
          (newItem) => {
            mainItemRemito = newItem;
          }
        "
        :sinRuta="true"
      />
      <!-- <v-btn @click="dialogRemitos = true" color="primary" v-if="create_access">
        {{ $t("Crear") + " " + $t("Remitos") }}
      </v-btn> -->

      <v-dialog v-model="dialog_import" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" outlined class="ml-2" v-bind="attrs" v-on="on" v-if="create_access">
            {{ $t("Importar_remitos") }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5 lighten-2" style="background-color:#4caf50">
            {{ $t("Importar_remitos") }}
          </v-card-title>
          <v-card-text class="pb-2 pl-4">
            <h6 class="mt-3">{{ $t("Seleccione_archivo") }}</h6>
            <!--   <a style="color:blue" href="/files/excel_base_clientes_basica.xlsx" v-text="'Descargar planilla de ejemplo basica'" v-show="import_type != null && import_type.id == 0" />
            <a style="color:blue" href="/files/excel_base_clientes_completa.xlsx" v-text="'Descargar planilla de ejemplo completa'" v-show="import_type != null && import_type.id == 1" />
 -->
            <v-combobox
              v-model="import_type"
              data-vv-as="Tipo de importación"
              name="import_type"
              item-text="name"
              item-value="id"
              :items="import_types"
              :label="$t('Import_type')"
              autocomplete="off"
              v-validate="'required'"
            ></v-combobox>
            <span class="red--text">{{ errors.first("import_type") }}</span>

            <v-file-input class="pt-0" :label="$t('Archivo_excel')" truncate-length="10" v-model="excelFile" @change="loadExcel"></v-file-input>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="(dialog_import = false), (excelFile = '')">
              {{ $t("Cerrar") }}
            </v-btn>
            <v-btn color="success" text @click="createMassive()">
              {{ $t("Crear_masivamente") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--Filter-->
      <div class="row mt-2 mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="remito_filter.length > 0"
                      v-model="dateInicio_filter"
                      :label="$t('Fecha_desde')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateInicio_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-4">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :disabled="remito_filter.length > 0" v-model="dateFin_filter" :label="$t('Fecha_hasta')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateFin_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>

              <div class="col-md-4">
                <v-text-field data-vv-as="remito" :label="$t('Remito')" name="remito" v-model="remito_filter"></v-text-field>
              </div>
              <!--   <div class="col-md-4">
                <v-combobox
                  v-model="cliente_filter"
                  disabled
                  name="client"
                  item-text="nameClient"
                  item-value="id"
                  :items="clients"
                  :label="$t('Cliente')"
                  autocomplete="off"
                  :clearable="true"
                ></v-combobox>
              </div> -->
            </div>
            <!-- fin row1 -->

            <div class="row">
              <!--  <div class="col-md-4">
                <v-text-field
                  data-vv-as="remito"
                  :label="$t('Remito')"
                  name="remito"
                  v-model="remito_filter"
                ></v-text-field>
              </div> -->
              <div class="col-md-4">
                <v-combobox v-model="state_filter" name="state" item-text="name" item-value="id" :items="states" :label="$t('Estado')" autocomplete="off" :clearable="true"></v-combobox>
              </div>
              <div class="col-md-7"></div>

              <div class="col-md-1 d-flex align-items-right justify-content-end pt-8">
                <v-btn color="primary" dark @click="loadPageCustom(service, 0)">{{ $t("Buscar") }}</v-btn>
              </div>

              <!-- fin row2 -->
            </div>
          </div>
        </div>
      </div>

      <v-card v-if="list_access">
        <div class="row">
          <div class="col-md-12 p-4">
            <v-card-title>
              <span class="text-uppercase">{{ page_title }}</span>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
            </v-card-title>
            <hr class="mb-0" />
            <v-data-table
              :headers="headers"
              :items="listSelectable"
              :loading="loading"
              :loading-text="$t('cargando_espere')"
              v-model="selected"
              show-select
              :single-select="false"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item="props">
                <tr>
                  <td>
                    <v-checkbox
                      :disabled="!(props.item.estate.id == remitosService.AUTORIZADO || props.item.estate.id == remitosService.RETENIDO)"
                      :input-value="props.isSelected"
                      style="margin:0px;padding:0px ;"
                      hide-details
                      @change="props.select($event)"
                    />
                  </td>
                  <td>{{ props.item.remito }}</td>
                  <td>
                    {{ props.item.subClient != null ? props.item.subClient.nameSubClient : "" }}
                  </td>
                  <td>
                    {{ props.item.subClient != null ? props.item.subClient.subClientAddress : " " }}
                  </td>
                  <td>
                    {{ props.item.pallets }}
                  </td>
                  <td>
                    {{ props.item.packages }}
                  </td>
                  <td>
                    {{ props.item.cubicMeters }}
                  </td>
                  <td>
                    {{ props.item.kg }}
                  </td>

                  <td>
                    {{ new Intl.NumberFormat("es-EN").format(props.item.declaredValue) }}
                  </td>

                  <td
                    style="min-width:135px"
                    v-html="getState(props.item.estate.id)"
                    :title="
                      props.item.estate.id == serviceRemitos.ENTREGADO || props.item.estate.id == serviceRemitos.NO_ENTREGADO
                        ? new Date(props.item.delivered).toISOString().substr(0, 10) + ' | ' + getHora(new Date(props.item.delivered))
                        : ''
                    "
                  ></td>

                  <td class="text-center px-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="violet" class="mr-2" @click="infoRemito(props.item)">
                          image
                        </v-icon>
                      </template>
                      <span>{{ $t("Ver_imagen") }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="blue" class="mr-2" @click="editItemRemitos(props.item)" v-if="update_access">
                          edit
                        </v-icon>
                      </template>
                      <span>{{ $t("Editar") }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItemRemitos(props.item)">
                          remove_red_eye
                        </v-icon>
                      </template>
                      <span>{{ $t("Ver") }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="red" class="mr-1" @click="deleteItemRemito(props.item)" v-if="delete_access">
                          delete
                        </v-icon>
                      </template>
                      <span>{{ $t("Eliminar") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning"> {{ $t("sin_resultados") + search }}". </v-alert>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>

      <div v-if="cant_pages > 1" class="text-center">
        <v-pagination
          v-if="list_access"
          class="mt-5 mb-5"
          v-model="current_page"
          :length="cant_pages"
          total-visible="10"
          circle
          @next="loadPageCustom(service)"
          @previous="loadPageCustom(service)"
          @input="loadPageCustom(service)"
        ></v-pagination>
      </div>

      <v-dialog v-model="dialog_remitos_no_ingresados" width="500">
        <v-card>
          <v-card-title class="headline">
            {{ $t("Error") }}
          </v-card-title>
          <v-list-item-title class="ml-4 mt-2">
            {{ $t("Remitos_ingresados") + cant_remitos_ingresados }}
          </v-list-item-title>
          <v-list-item-title class="ml-4 mt-2">
            {{ $t("Remitos_sin_ingresar") }}
          </v-list-item-title>
          <v-card-text class="pb-0" style="padding-top:0px !important ; height:30px" v-for="(item, i) in remitos_no_ingresados" :key="i">
            <v-list-item>
              <v-list-item-title> • {{ item.numRemito }}</v-list-item-title>
            </v-list-item>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialog_remitos_no_ingresados = false">
              {{ $t("Cerrar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div>
      <InfoRemito button_name="Informacion remito" modal_title="Informacion remito" :item="mainItemRemito" :dialog="dialogInfo" @updateDialog="updateDialogInfo" />

      <DialogDelete :dialog_delete="dialog_deleteRemito" @updateDialogDelete="dialog_deleteRemito = false" @deleteItemConfirm="deleteItemConfirmRemito" />
    </div>
    <v-dialog v-model="dialog_editremitos" fullscreen>
      <v-card>
        <v-toolbar dark class="headline">
          <v-btn class="ml-4" icon dark @click="dialog_editremitos = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Remitos seleccionados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="editEstadoRemitos">
              {{ $t("Editar") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="pb-0" style="padding-top:0px !important">
          <h5 class="mt-4">
            Indica el nuevo estado de los remitos seleccionados
          </h5>

          <div class="col-md-2 mt-3 mb-3 pl-0">
            <v-combobox v-model="state_edit" name="state" item-text="name" item-value="id" :items="states_edit" :label="$t('Estado')" autocomplete="off" :clearable="true"></v-combobox>
          </div>
          <v-data-table :headers="headers" :items="selected" :loading="loading" :loading-text="$t('cargando_espere')" style="border: solid 1px #1c1c1c">
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.remito }}</td>

                <td>
                  {{ props.item.subClient != null ? props.item.subClient.nameSubClient : " " }}
                </td>
                <td>
                  {{ props.item.subClient != null ? props.item.subClient.subClientAddress : " " }}
                </td>
                <td>
                  {{ props.item.pallets }}
                </td>
                <td>
                  {{ props.item.packages }}
                </td>
                <td>
                  {{ props.item.cubicMeters }}
                </td>
                <td>
                  {{ props.item.kg }}
                </td>

                <td>
                  {{ new Intl.NumberFormat("es-EN").format(props.item.declaredValue) }}
                </td>

                <td style="min-width:135px" v-html="getState(props.item.estate.id)"></td>

                <td class="justify-content-center px-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="blue" class="mr-2" @click="infoRemito(props.item)">
                        image
                      </v-icon>
                    </template>
                    <span>{{ $t("Ver_imagen") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="red" class="mr-1" @click="removeFromSelected(props.index)" v-if="update_access">
                        indeterminate_check_box
                      </v-icon>
                    </template>
                    <span>{{ $t("Remover_seleccionado") }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning"> {{ $t("sin_resultados") + search }}". </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ModalRemitos from "./ModalRemitos.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import { clientService } from "@/libs/ws/clientService";
import { subClientService } from "@/libs/ws/subClientService";
import { remitosService } from "@/libs/ws/remitosService";
import { countryService } from "@/libs/ws/countryService";
import readXlsxFile from "read-excel-file";
import { clusterService } from "@/libs/ws/clusterService";
import Constant from "@/constants/";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
import InfoRemito from "@/views/Remitos/InfoRemito.vue";
import axios from "axios";
export default {
  components: {
    Title,
    ModalRemitos,
    DialogDelete,
    InfoRemito,
  },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      remitosService: remitosService,
      dialogInfo: false,
      search: "",
      ruta_id: 0,
      loading: false,
      dialog_rutas: false,
      dialog_asignar_ruta: false,
      selected: [],
      fakeRuta: {},
      excelFile: null,
      dialog_import: false,
      state_edit: "",
      excelupload: [],
      list: [],
      remitos_pendientes: [],
      ruta: {},
      page_title: "Remitos",
      serviceRemitos: remitosService,
      dialogRemitos: false,
      editedIndexRemito: -1,
      viewdIndexRemito: -1,
      deleteIndexRemito: -1,
      clients: [],
      subclients: [],
      clusters: [],
      states: [],
      localidades: [],
      provincias: [],
      dateInicio_filter: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      dateFin_filter: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      cliente_filter: null,
      state_filter: "",
      remito_filter: "",
      onlyPendientes_filter: false,
      mainItemRemito: {
        IdTrip: "",
        Orden: "",
        IdClient: "",
        IdSubClient: "",
        Remito: "",
        SubClients: "",
        Clients: "",
        declaredValue: 0,
        pallets: 0,
        packages: 0,
        kg: 0,
        cubicMeters: 0,
        images: "",
        seleccionado: false,
      },
      defaultItemRemito: {
        IdTrip: "",
        Orden: "",
        IdClient: "",
        IdSubClient: "",
        Remito: "",
        Observation: "",
        Assistant: false,
        Delays: false,
        Stays: false,
        Turns: false,
        SubClients: "",
        Clients: "",
        declaredValue: 0,
        pallets: 0,
        packages: 0,
        kg: 0,
        cubicMeters: 0,
        images: "",
        seleccionado: false,
      },
      open_filter: false,
      loading_locality: true,

      headers: [
        {
          text: "N°" + this.$t("Remito"),
          align: "left",
          sortable: true,
          value: "Remito",
          class: "lighten-2",
        },
        {
          text: this.$t("Subcliente"),
          align: "left",
          sortable: true,
          value: "subclients.subClientName",
          class: "lighten-2",
        },

        {
          text: this.$t("Direccion"),
          align: "left",
          sortable: true,
          value: "subclients.subClientAddress",
          class: "lighten-2",
        },
        {
          text: this.$t("Pallets"),
          align: "left",
          sortable: true,
          value: "pallets",
          class: "lighten-2",
        },
        {
          text: this.$t("Bultos"),
          align: "left",
          sortable: true,
          value: "packages",
          class: "lighten-2",
        },
        {
          text: "M3",
          align: "left",
          sortable: true,
          value: "cubicMeters",
          class: "lighten-2",
        },
        {
          text: "KG",
          align: "left",
          sortable: true,
          value: "kg",
          class: "lighten-2",
        },
        {
          text: this.$t("Valor_declarado"),
          align: "left",
          sortable: true,
          value: "declaredValue",
          class: "lighten-2",
        },
        {
          text: this.$t("Estado"),
          align: "left",
          sortable: true,
          value: "state",
          class: "lighten-2",
        },
        {
          text: this.$t("Accion"),
          align: "left",
          sortable: true,
          value: "enable",
          class: "lighten-2",
        },
      ],
      bultos_acum: 0,
      kg_acum: 0,
      m3_acum: 0,
      pallets_acum: 0,
      valor_acum: 0,
      dialog_deleteRemito: false,
      remitos_no_ingresados: [],
      dialog_remitos_no_ingresados: false,
      remitos_upload: [],
      cant_remitos_ingresados: 0,
      import_types: [
        { id: 0, name: "Importacion basica" },
        { id: 1, name: "Importacion completa" },
      ],
      import_type: null,
      importObject: {
        NameClient: "",
        ExternalCodSubClient: "",
        NameSubClient: "",
        CUITSubClient: "",
        AddressSubClient: "",
        CPSubClient: "",
        LocalitySubClient: "",
        ProvinceSubClient: "",
        CountrySubClient: "",
        NumRemito: "",
        DeclaredValue: "",
        Weight: "",
        CubicMeters: "",
        Estate: "",
        Pallet: "",
        Package: "",
        Observation: "",
      },
      service: remitosService,
      client_NameClient: "",
      dialog_editremitos: false,
      states_edit: [
        { id: 1, name: "AUTORIZADO" },
        { id: 2, name: "RETENIDO" },
      ],
    };
  },
  mounted() {
    this.getClientID();
    this.loadClients();
    this.loadSubclients();
    this.loadStates();
    //  this.loadClusters();
  },
  computed: {
    formBtnRemito() {
      if (this.editedIndexRemito === -1 && this.viewdIndexRemito === -1) {
        return "Crear";
      } else if (this.viewdIndexRemito === -1) {
        return "Editar";
      }
      return "";
    },
    listSelectable() {
      return this.list.map((x) => ({
        ...x,
        isSelectable: x.estate.id == remitosService.AUTORIZADO || x.estate.id == remitosService.RETENIDO,
      }));
    },
  },
  watch: {
    selected() {},
  },
  methods: {
    infoRemito(item) {
      this.errors.clear();
      this.mainItemRemito = Object.assign({}, item);
      this.dialogInfo = true;
    },
    updateDialogInfo(value) {
      if (!value) {
        this.dialogInfo = false;
        return;
      }
    },
    getClientID() {
      var rta = cryptoUtils.decrypt(localStorage.getItem("cliente"), Constant.SECRET_PHRASE);
      rta = rta.split("-");
      var cliente_aux = { id: parseInt(rta[0]), nameClient: rta[1] };
      this.cliente_filter = Object.assign({}, cliente_aux);
      this.mainItemRemito.client = Object.assign({}, cliente_aux);
      this.client_NameClient = rta[1];

      //Ahora cargo los remitos
      this.loadPageCustom(this.service);
    },
    loadPageCustom(service, startIndex = (this.current_page - 1) * this.$store.state.config.items_per_page) {
      this.loadListCustom(service, startIndex);
    },
    subcli: (item) => (item.id == 0 ? "" : item.nameSubClient + " (" + item.subClientAddress + ")"),
    loadListCustom(service, startIndex = 0) {
      this.loading = true;
      var params = {
        fecha_desde: this.formatDate(this.dateInicio_filter),
        fecha_hasta: this.formatDate(this.dateFin_filter),
        client_id: this.cliente_filter != null ? this.cliente_filter.id : 0,
        NumRemito: this.remito_filter,
        Estado: this.state_filter != "" ? this.state_filter.id : 0,
      };
      service
        .filterCustomer(params, startIndex, this.$store.state.config.items_per_page)
        .then((response) => {
          if (startIndex == 0) {
            this.current_page = 1;
          }
          console.log(response.list);
          if (response.list != null) {
            this.list = response.list;
          } else {
            this.list = [];
          }
          this.cant_pages = Math.ceil(response.quantity / this.$store.state.config.items_per_page);
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadExcel() {
      let xlsxfile = this.excelFile;
      readXlsxFile(xlsxfile).then((rows) => {
        rows.splice(0, 1);
        this.excelupload = rows;
      });
    },
    loadSubclients() {
      this.loading_subclientes = true;
      subClientService
        .filter(this.search == "" ? " " : this.search, 0, 100, 1)
        .then((response) => {
          this.subclients = response.list;
          this.loading_subclientes = false;
        })
        .catch((e) => {
          console.log("service.load error");
          this.loading_subclientes = false;
          console.log(e);
        });
    },
    /*   loadClusters() {
      clusterService
        .getAll()
        .then((response) => {
          this.clusters = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    }, */
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },

    loadStates() {
      remitosService
        .GetStates()
        .then((response) => {
          this.states = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    provincia_qty: (item) => (item.id == 0 ? "" : item.name + " (" + item.quantity + ")"),
    localidad_qty: (item) => (item.id == 0 ? "" : item.name + " (" + item.quantity + ")"),

    getLocalidadesFilters() {
      var arrayLocalidades = [];
      if (this.localidad_filter.length != 0) {
        this.localidad_filter.forEach((element) => {
          arrayLocalidades.push(element.id);
        });
      }
      return arrayLocalidades;
    },
    updateDialogRemitos(value, tipo = 0) {
      if (!value) {
        this.closeRemitos(tipo);
        this.loadPageCustom(this.service);
        return;
      }
    },
    viewItemRemitos(item) {
      this.viewdIndexRemito = 1;
      this.mainItemRemito = Object.assign({}, item);
      this.dialogRemitos = true;
    },
    editItemRemitos(item) {
      this.errors.clear();
      this.editedIndexRemito = 1;
      this.mainItemRemito = Object.assign({}, item);
      this.dialogRemitos = true;
    },
    closeRemitos(tipo) {
      if (tipo == 0) {
        this.mainItemRemito = Object.assign({}, this.defaultItemRemito);
        this.dialogRemitos = false;
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
      } else if (tipo == 1) {
        var orden = 0;
        orden = this.mainItemRemito.Orden + 1;
        this.mainItemRemito = Object.assign({}, this.defaultItemRemito);
        this.mainItemRemito.Orden = orden;
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
        this.dialogRemitos = true;
      } else {
        this.mainItemRemito.Orden = this.mainItemRemito.Orden + 1;
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
        this.dialogRemitos = true;
      }
    },

    rowEmpty(element) {
      return (
        element[1] == null &&
        element[2] == null &&
        element[3] == null &&
        element[4] == null &&
        element[5] == null &&
        element[6] == null &&
        element[7] == null &&
        element[8] == null &&
        element[9] == null &&
        element[10] == null &&
        element[11] == null &&
        element[12] == null &&
        element[13] == null &&
        element[14] == null &&
        element[15] == null
      );
    },
    validarDatos(element, index) {
      var object = { value: true, msg: "" };

      if (this.import_type.id == 0) {
        if (element[1] == null && element[2] == null) {
          return {
            value: false,
            msg: "Error, El campo sub cliente external code o name sub cliente es obligatorio fila " + (index + 1),
          };
        }
      } else {
        if (element[1] == null) {
          return {
            value: false,
            msg: "Error, El campo Subcliente es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[2] == null) {
          return {
            value: false,
            msg: "Error, El campo Pais es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[3] == null) {
          return {
            value: false,
            msg: "Error, El campo Provincia es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[4] == null) {
          return {
            value: false,
            msg: "Error, El campo Localidad es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[5] == null) {
          return {
            value: false,
            msg: "Error, El campo Direccion es obligatorio en la fila " + (index + 1),
          };
        }
      }

      return object;
    },
    createMassive() {
      if (this.import_type == null) {
        return;
      }

      var params = Object.assign({}, this.importObject);
      this.remitos_upload = [];
      var validarDatosRta = {};
      var flag = false;

      //import type 0
      if (this.import_type != null && this.import_type.id == 0) {
        this.excelupload.forEach((element, index) => {
          if (!this.rowEmpty(element)) {
            validarDatosRta = this.validarDatos(element, index);
            if (validarDatosRta.value) {
              params.NameClient = element[0];
              params.ExternalCodSubClient = element[1] != null ? element[1].toString() : "";
              params.NameSubClient = element[2] != null ? element[2].toString() : "";
              params.NumRemito = element[3] != null ? element[3].toString() : "";
              params.DeclaredValue = element[4] != null ? parseFloat(element[4]) : 0;
              params.Weight = element[5] != null ? parseFloat(element[5]) : 0;
              params.CubicMeters = element[6] != null ? parseFloat(element[6]) : 0;
              params.Pallet = element[7] != null ? parseFloat(element[7]) : 0;
              params.Package = element[8] != null ? parseFloat(element[8]) : 0;
              params.Rack = element[9] != null ? parseFloat(element[9]) : 0;
              params.Bobina = element[10] != null ? parseFloat(element[10]) : 0;
              params.Tambores = element[11] != null ? parseFloat(element[11]) : 0;
              params.Observation = element[12] != null ? element[12].toString() : "";
              params.Estate = element[13] != null ? element[13].toString() : "";

              this.remitos_upload.push(params);
              params = Object.assign({}, this.importObject);
            } else {
              this.$toast.error(validarDatosRta.msg);
              flag = true;
            }
          }
        });
      } else {
        //import type 1

        this.excelupload.forEach((element, index) => {
          if (!this.rowEmpty(element)) {
            validarDatosRta = this.validarDatos(element, index);
            if (validarDatosRta.value) {
              params.NameClient = this.client_NameClient;
              params.NameSubClient = element[1] != null ? element[1].toString() : "";
              params.CountrySubClient = element[2] != null ? element[2].toString() : "";
              params.ProvinceSubClient = element[3] != null ? element[3].toString() : "";
              params.LocalitySubClient = element[4] != null ? element[4].toString() : "";
              params.AddressSubClient = element[5] != null ? element[5].toString() : "";
              params.NumRemito = element[6] != null ? element[6].toString() : "";
              params.DeclaredValue = element[7] != null ? parseFloat(element[7]) : 0;
              params.Weight = element[8] != null ? parseFloat(element[8]) : 0;
              params.CubicMeters = element[9] != null ? parseFloat(element[9]) : 0;
              params.Pallet = element[10] != null ? parseFloat(element[10]) : 0;
              params.Package = element[11] != null ? parseFloat(element[11]) : 0;
              params.Rack = element[12] != null ? parseFloat(element[12]) : 0;
              params.Bobina = element[13] != null ? parseFloat(element[13]) : 0;
              params.Tambores = element[14] != null ? parseFloat(element[14]) : 0;
              params.Observation = element[15] != null ? element[15].toString() : "";
              params.Estate = element[16] != null ? element[16].toString() : "";

              this.remitos_upload.push(params);
              params = Object.assign({}, this.importObject);
            } else {
              this.$toast.error(validarDatosRta.msg);
              flag = true;
            }
          }
        });
      }

      if (!flag) {
        this.dialog_import = false;
        var remitosobjeto = { Remitos: this.remitos_upload };
        remitosService
          .createMassiveInBag(remitosobjeto, this.import_type.id)
          .then((response) => {
            if (response.ingresados > 0) {
              this.$toast.success(this.$t("se_creo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.loadPageCustom(this.service);
              this.excelFile = "";
              this.cant_remitos_ingresados = response.ingresados;
            } else {
              this.$toast.error(this.$t("Ocurrio_error"), "Error");
            }
            if (response.noIngresados != 0) {
              this.remitos_no_ingresados = response.remitosNoIngresados;
              this.dialog_remitos_no_ingresados = true;
            }
          })
          .catch((response) => {
            this.$toast.error(this.$t("Ocurrio_error"), "Error");
          });
      }
    },
    deleteItemRemito(item) {
      this.deleteIndexRemito = this.list.indexOf(item);
      this.deleteId = item.id;
      this.dialog_deleteRemito = true;
    },
    deleteItemConfirmRemito() {
      this.delete(this.serviceRemitos);
      this.dialog_deleteRemito = false;
      this.loadPageCustom(this.service);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    removeFromSelected(index) {
      this.selected.splice(index, 1);
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },
    editEstadoRemitos() {
      if (this.state_edit == "") {
        this.$toast.error("Debe seleccionar un estado para actualizar los remitos", "Error");
        return;
      }

      var params = { Remitos: [], Estado: 0 };
      this.selected.forEach((element) => {
        params.Remitos.push(element.id);
      });
      params.Estado = this.state_edit.id;
      remitosService
        .ChangeState(params)
        .then((response) => {
          if (response != null) {
            this.$toast.success(this.$t("se_actualizo_ok"), "OK");
            this.loadPageCustom(this.service);
            this.dialog_editremitos = false;
            this.state_edit = null;
            this.selected = [];
          }
        })
        .catch((response) => {
          this.$toast.error(this.$t("Ocurrio_error"), "Error");
        });
    },
    getState(estado) {
      return remitosService.getStatesHTML(estado);
    },
  },
};
</script>
