var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"pages":[{ icon: 'clipboard-list', page: _vm.page_title.toUpperCase() }]}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.create_access)?_c('Modal',{attrs:{"button_name":_vm.$t('Crear') + ' ' + _vm.modal_title,"modal_title":_vm.modal_title,"formBtn":_vm.formBtn,"dialog":_vm.dialog,"parent":_vm.parent,"service":_vm.service,"item":_vm.mainItem},on:{"updateDialog":_vm.updateDialog,"loadList":function($event){_vm.loadList(_vm.service, _vm.name_filter, (_vm.current_page - 1) * _vm.$store.state.config.items_per_page)},"updateItem":function (newItem) {
          _vm.mainItem = newItem;
        }}}):_vm._e(),(_vm.list_access)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"filter"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Filtros")))]),_c('font-awesome-icon',{attrs:{"icon":"filter"}})],1),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('v-text-field',{attrs:{"data-vv-as":"nombre","label":_vm.$t('Nombre') + '*',"name":"zone"},model:{value:(_vm.name_filter),callback:function ($$v) {_vm.name_filter=$$v},expression:"name_filter"}})],1),_c('div',{staticClass:"col-md-4"},[_c('v-checkbox',{attrs:{"label":_vm.$t('Activo')},model:{value:(_vm.active_filter),callback:function ($$v) {_vm.active_filter=$$v},expression:"active_filter"}})],1),_c('div',{staticClass:"col-md-4 d-flex align-items-center justify-content-end pr-10"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.loadList(_vm.service)}}},[_vm._v(_vm._s(_vm.$t("Buscar")))])],1)])])])]):_vm._e(),(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.page_title))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('Buscar'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"single-expand":false,"loading":_vm.loading,"expanded":_vm.expanded,"show-expand":"","hide-default-footer":"","disable-pagination":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
        var expand = ref.expand;
        var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',[(!isExpanded)?_c('font-awesome-icon',{staticClass:"cursor",attrs:{"icon":"chevron-down"},on:{"click":function($event){return expand(!isExpanded)}}}):_c('font-awesome-icon',{staticClass:"cursor",attrs:{"icon":"chevron-up"},on:{"click":function($event){return expand(!isExpanded)}}})],1),_c('td',{staticClass:"cursor",on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" "+_vm._s((_vm.pagination.page - 1) * _vm.pagination.itemsPerPage + index + 1)+" ")]),_c('td',{staticClass:"cursor",on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-center  px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.create_access)?{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.addRemitoItem(item)}}},on),[_vm._v(" add ")])]}}:null],null,true)},[_c('span',[_vm._v("Agregar item")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewItem(item)}}},on),[_vm._v(" remove_red_eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Ver")))])]),(_vm.update_access)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Editar")))])]):_vm._e(),(_vm.delete_access)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Eliminar")))])]):_vm._e()],1)])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"background-color: grey  lighten-3",attrs:{"headers":_vm.headersRemitosItems,"items":item.items,"search":_vm.search,"loading-text":_vm.$t('cargando_espere'),"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.desde))]),_c('td',[_vm._v(_vm._s(props.item.hasta))]),_c('td',[_vm._v(_vm._s(props.item.measurementUnitType.name))]),_c('td',[_vm._v(_vm._s(props.item.porecentaje))]),_c('td',[_vm._v(_vm._s(props.item.price))]),_c('td',{staticClass:"text-center  px-0"},[(_vm.update_access)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editTarifaItem(item, props.item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Editar")))])]):_vm._e(),(_vm.delete_access)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteTarifaItem(item, props.item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Eliminar")))])]):_vm._e()],1)])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t("Sin_resultados") + _vm.search)+" ")])]},proxy:true}],null,true)})],1)]}}],null,false,25181940)})],1):_vm._e(),(_vm.create_access)?_c('ModalTarifaItem',{attrs:{"button_name":_vm.$t('Crear') + ' tarifa item',"modal_title":"Tarifa item","formBtn":_vm.formBtnItem,"dialog":_vm.dialog_tarifa_item,"parent":_vm.parent,"service":_vm.service,"item":_vm.mainTarifaItem},on:{"updateDialog":function (value) {
          _vm.dialog_tarifa_item = value;
          _vm.closeTarifaItem();
        },"loadList":function($event){_vm.loadList(_vm.service, _vm.name_filter, (_vm.current_page - 1) * _vm.$store.state.config.items_per_page)},"updateItem":function (value) {
          _vm.mainTarifaItem = value;
        }}}):_vm._e(),_c('DialogDelete',{attrs:{"dialog_delete":_vm.dialog_delete},on:{"updateDialogDelete":_vm.updateDialogDelete,"deleteItemConfirm":_vm.deleteConfirm}}),_c('DialogDelete',{attrs:{"dialog_delete":_vm.dialog_delete_item},on:{"updateDialogDelete":function (value) {
          _vm.dialog_delete_item = value;
        },"deleteItemConfirm":_vm.deleteTarifaItemConfirm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }