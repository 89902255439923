<template>
  <GmapMap
    :center="getCenter()"
    :zoom="9"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      disableDefaultUi: true,
    }"
    map-type-id="terrain"
    style="width: 100%; height: 600px"
  >
    <div v-for="driver of drivers" :key="driver.numRuta">
      >
      <GmapMarker
        v-if="driver.geo.latitud != null && driver.geo.longitud != null"
        :position="getPosition(driver.geo.latitud, driver.geo.longitud)"
        :icon="getIcon(driver)"
        :clickable="true"
        :draggable="false"
        @click="driver.open = true"
      >
        <GmapInfoWindow :opened="driver.open" @closeclick="driver.open = false">
          <div style="padding:5px">
            <p class="fs-15 mb-0">
              <b>{{ driver.nombreChofer }}</b>
            </p>
            <p class="fs-15">{{ driver.patente }}</p>
            <p class="fs-15">Ruta: #{{ driver.numRuta }}</p>
            <p class="fs-15">Ultima actualizacion: {{ transformDate(driver.geo.lastUpdatePosition) }}</p>
          </div>
        </GmapInfoWindow>
      </GmapMarker>
    </div>
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
export default {
  name: "GeoDrivers",
  components: {},
  props: {
    drivers: Array,
  },
  data() {
    return {
      lngMin: -58.38164061602919,
      latMin: -34.60320344068884,
      lngMax: -58.38164061602919,
      latMax: -34.60320344068884,
      open: false,
    };
  },
  computed: {
    google: gmapApi,
    driversObject() {
      return this.drivers;
    },
  },
  watch: {
    driversObject() {
      this.drivers.forEach((element) => {
        this.$set(element, "open", false);
      });
    },
  },
  mounted() {},
  methods: {
    getCenter() {
      var lat = (this.latMax + this.latMin) / 2;
      var lng = (this.lngMax + this.lngMin) / 2;
      return { lat: lat, lng: lng };
    },
    getIcon(driver) {
      //si geo.lastUpdatePosition es mayor a 3 horas, el icono es rojo
      if (driver.geo.lastUpdatePosition) {
        var lastUpdate = new Date(driver.geo.lastUpdatePosition);
        var now = new Date();
        var diff = now - lastUpdate;
        if (diff > 10800000) {
          return require("@/assets/images/marker-truck-black.svg");
        } else {
          return require("@/assets/images/marker-truck-red.svg");
        }
      }
    },
    transformDate(date) {
      //2024-03-12T12:36:57.0076071-03:00 to 12-03-2024 12:36:57
      //remove el -03:00
      date = date.replace("-03:00", "");
      return (
        date
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-") +
        " " +
        date.split("T")[1].split(".")[0]
      );
    },
    getPosition(lat, lng) {
      if (this.latMax == null || this.latMax < parseFloat(lat)) {
        this.latMax = parseFloat(lat);
      }

      if (this.latMin == null || this.latMin > parseFloat(lat)) {
        this.latMin = parseFloat(lat);
      }

      if (this.lngMax == null || this.lngMax < parseFloat(lng)) {
        this.lngMax = parseFloat(lng);
      }

      if (this.lngMin == null || this.lngMin > parseFloat(lng)) {
        this.lngMin = parseFloat(lng);
      }

      this.center = this.getCenter();

      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    },
  },
};
</script>
