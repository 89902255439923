<template>
  <div>
    <v-dialog v-model="dialog_delete" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirmar</v-card-title>

        <v-card-text>
          <p class="mt-5 mb-0 fs-16 text-center">
            ¿Esta seguro que quiere eliminar este elemento?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="$emit('updateDialogDelete', false)"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('deleteItemConfirm')"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog_delete: Boolean,
  },
  data: () => ({}),
  methods: {},
};
</script>
