<template>
    <v-dialog v-model="dialog" max-width="650">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
  
        <v-card-text>
          <p class="mb-0 fs-16 text-center">No puede cambiar el estado de este remito, para hacerlo edite el orden de los remitos colocandolo bajo el ultimo remito en estado ENTREGADO O NO ENTREGADO.</p>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
  
          <v-btn color="red darken-1" text @click="$emit('close')">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      dialog: Boolean,
      close: Function,
    },
    data() {
      return {};
    },
    watch: {
      dialog() {},
    },
    mounted() {},
    methods: {},
  };
  </script>
  